<template>
    <v-card class="mx-3 pa-4" elevation="0">
      <v-card-text class='d-flex flex-wrap'>
        <v-row>
          <v-col>
            <div class='field-name'>{{ $filters.capitalize($t("part_categories.label")) }}</div>
            <p class="text-body-1 text--primary">
            {{ $store.state.stock_parts.edit.category.name }}
            </p>
          </v-col>
          <v-col>
            <div class='field-name'>{{ $filters.capitalize($t("producer_types.label", 1)) }}</div>
            <ul
                v-if="$store.state.stock_parts.edit.category.producer_types !=undefined"
            >
                <li
                    v-for="producer in $store.state.stock_parts.edit.category
                             .producer_types"
                    v-bind:key="producer.pk"
                    >
                    {{ $filters.capitalize(producer.name) }}
                </li>
            </ul>
              <ul>
                <li
                    v-if="
                          $store.state.stock_parts.edit.category.producer_types
                          .length == 0"
                    >
                    {{ $filters.capitalize($t("part_edition.generic_type")) }}
                </li>
              </ul>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div class='field-name'>{{ $filters.capitalize($t("stock_parts.name")) }}</div>
            <p class="text-body-1 text--primary">
            {{ $store.state.stock_parts.edit.name }}
            </p>
          </v-col>
          <v-col>
            <div class='field-name'>{{ $filters.capitalize($t("stock_parts.name_en")) }}</div>
            <p class="text-body-1 text--primary">
            {{ $store.state.stock_parts.edit.name_en }}
            </p>
          </v-col>
          <v-col>
            <div class='field-name'>{{ $filters.capitalize($t("stock_parts.ref")) }}</div>
            <p class="text-body-1 text--primary">
            {{ $store.state.stock_parts.edit.ref }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class='field-name'>{{ $filters.capitalize($t("unity.label")) }}</div>
            <p class="text-body-1 text--primary">
            {{ $filters.capitalize($filters.unityFilter(
            $store.state.stock_parts.edit.unity)) }}
            </p>
          </v-col>
          <v-col>
            <div class='field-name'>{{ $filters.capitalize($t("stock_parts.price")) }}</div>
            <p class="text-body-1 text--primary">
            {{
            $filters.formatPrice($store.state.stock_parts.edit.price
            , $store.state.stock_parts.edit.price_currency)
            }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class='field-name'>{{ $filters.capitalize($t("suppliers.label", 10)) }}</div>
            <ul v-if="$store.state.stock_parts.edit.suppliers !== undefined">
              <li
                  v-for="supplier in $store.state.stock_parts.edit.suppliers"
                  v-bind:key="supplier.pk"
                  >
                  {{ $filters.capitalize(supplier.name) }}
              </li>
            </ul>
          </v-col>
          <v-col>
            <div class='field-name'>{{ $filters.capitalize($t("stock_parts.is_locally_bought")) }}</div>
            <p
                class="text-body-1 text--primary"
                v-show="$store.state.stock_parts.edit.is_locally_bought"
                >
                {{ $filters.capitalize($t("stock_parts.is_locally_bought_.true")) }}
            </p>
            <p
                class="text-body-1 text--primary"
                v-show="!$store.state.stock_parts.edit.is_locally_bought"
                >
                {{ $filters.capitalize($t("stock_parts.is_locally_bought_.false")) }}
            </p>
          </v-col>
          <v-col>
            <div class='field-name'>{{ $filters.capitalize($t("stock_parts.image")) }}</div>
            <v-img
                :src="$store.state.stock_parts.edit.image"
                style="border: 1px dashed grey"
                max-height="210"
                max-width="280"
                placeholder="static/camera_off.png"
                contain
                />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    </template>
    <script>
      export default{} 
    </script>
<style>
</style>
