<template>
<v-container>
    <div style="display: none">
      <EditDialog
        v-bind:model="editPurchaseLine"
        v-bind:moduleName="'purchase_lines'"
        v-bind:btnName="''"
        v-bind:formTitle="$t('edit_item')"
        @save="savePurchaseLineEvent"
        @close="cleanupPurchaseLineDialog"
        ref="editDialogPurchaseLine"
      >
        <template v-slot:edit_content>
          <PurchaseLineForm
            v-bind:stockageId="stockage_id"
            v-bind:askPurchase="false"
          ></PurchaseLineForm>
        </template>
      </EditDialog>

      <SimpleDialog
        v-bind:model="viewSupplier"
        v-bind:btnName="''"
        v-bind:smallWidth="true"
        v-bind:formTitle="$t('purchase_view.title.view_supplier')"
        @close="cleanupViewSupplierDialog"
      >
        <template v-slot:content>
          <SupplierPrez v-bind:item="viewSupplierItem"></SupplierPrez>
        </template>
      </SimpleDialog>

      <EditDialog
        v-bind:model="editPurchase"
        v-bind:moduleName="'purchases'"
        v-bind:btnName="''"
        v-bind:formTitle="$t('edit_item')"
        @save="savePurchaseEvent"
        @close="cleanupPurchaseDialog"
        ref="editDialogPurchase"
      >
        <template #edit_content>
          <PurchaseForm v-bind:askReceivedAt="false"></PurchaseForm>

          <h4>{{$filters.capitalize( $t("purchase_lines.edition.content") ) }}</h4>
          <div v-if="editPurchase_item === null">
            <PurchaseLineFormSimplified
              v-for="(line, index) in selected"
              v-bind:key="line.pk"
              :line="line"
              :ordinal="index"
            ></PurchaseLineFormSimplified>
          </div>

          <div v-if="editPurchase_item !== null">
            <PurchaseLineFormSimplified
              v-for="(line, index) in editPurchase_item.lines"
              v-bind:key="line.pk"
              :line="line" 
              :ordinal="index"
              v-bind:creation="false"
            ></PurchaseLineFormSimplified>
          </div>
        </template>
      </EditDialog>

      <EditDialog
        v-bind:model="receivedPurchase"
        v-bind:moduleName="'purchases'"
        v-bind:btnName="''"
        v-bind:formTitle="$t('purchase_view.title.received_form')"
        @save="saveReceivedPurchaseEvent"
        @close="cleanupReceivedPurchaseDialog"
        ref="receivedDialogPurchase"
      >
        <template v-slot:edit_content>
          <ReceivedPurchaseForm></ReceivedPurchaseForm>
        </template>
      </EditDialog>

      <EditDialog
        v-bind:model="updateState"
        v-bind:moduleName="'purchases'"
        v-bind:btnName="''"
        v-bind:formTitle="$t('purchase_view.title.update_state_form')"
        @save="saveUpdateStateEvent"
        @close="cleanupUpdateStateDialog"
        ref="updateStateDialogPurchase"
      >
        <template v-slot:edit_content>
          <PurchaseForm
            v-bind:askInfos="false"
            v-bind:askReceivedAt="false"
          ></PurchaseForm>
        </template>
      </EditDialog>

      <v-dialog
        v-model="imageInLarge"
        max-width="90vw"
        transition="scale-transition"
        content-class="imageInLargeDialog"
        @click:outside="imageInLarge = false"
      >
        <v-btn
          class="mx-auto"
          icon="true"
          color="secondary"
          @click="imageInLarge = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-img :src="imageInLargeSrc" alt="" max-height="90vh"/>
      </v-dialog>
    </div>

    <v-card class="mx-auto my-3">
      <v-toolbar :flat="true" color="white" elevation="1">
        <MenuStock
          v-bind:stockage_id="stockage_id"
          v-bind:producer_type_id="producer_type_id"
          v-bind:loading="loadingStock"
          @chooseStock="menuChoose"
        ></MenuStock>

        <v-spacer></v-spacer>

        <v-toolbar-title style="font-weight: bolder"
          >{{$filters.capitalize( $t(`purchase_view.label`, 1) ) }} &mdash;
          {{$filters.capitalize( $t(`purchase_view.title.lines`, 10) ) }}
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <v-text-field
          v-model="searchText"
          label="Search"
          prepend-inner-icon="mdi-magnify"
          single-line
          variant="outlined"
          hide-details
        ></v-text-field>
      </v-toolbar>

      <v-data-table
        v-model="selected"
        :headers="headersPurchaseLines"
        :items="purchase_line_items"
        item-value="pk"
        :row-props="bgClass"
        :group-by="[{key:'stock_part.supplier_names', order:'asc'}]"
        :options.sync="optionsPurchaseLines"
        :server-items-length="pageSizePurchaseLines"
        :items-per-page-options='footerProps["items-per-page-options"]'
        :items-per-page-text='footerProps["items-per-page-text"]'
        :search="searchText"
        elevation="1"
        fixed-header
        show-select
        return-object
        >
        <template #group-header="{ item, columns, toggleGroup, isGroupOpen }">
          {{ isGroupOpen(item) ? "" : toggleGroup(item) }}
            <tr>
              <td :colspan="columns.length" class="group-header">
                <div class="group-header-content">
                  <VBtn size="small" variant="text" class="mr-2" @click="toggleGroup(item)"
                        :icon='isGroupOpen(item) ? "mdi-minus" : "mdi-plus"'>
                  </VBtn>
                  <span v-if="item.value === ''">{{$filters.capitalize($t(`purchase_view.no_supplier`))}}</span>
                  <span v-else>{{ item.value }}</span>
                </div>
              </td>
            </tr>
        </template>
        <template #item.stock_part.name="{ item }">
          <span
            v-if="
              ($store.getters.language === 'en' &&
                item.stock_part.name_en !== '') ||
              ($store.getters.language === 'fr' && item.stock_part.name === '')
            "
          >
            {{ item.stock_part.name_en }}
          </span>
          <span v-else>
            {{ item.stock_part.name }}
          </span>
        </template>

        <template #item.is_locally_bought="{ item }">
          <v-switch
            v-model="item.is_locally_bought"
            inset
            @update:modelValue="switchIsLocallyBought(item)"
          ></v-switch>
        </template>

        <template #item.stock_part.unity="{ item }">
          {{$filters.capitalize($filters.unityFilter( item.stock_part.unity ) ) }}
        </template>

        <template #item.created_at="{ item }">
          {{$filters.formatDate( item.created_at ) }}
        </template>

        <template #item.price="{ item }">
          {{ $filters.formatPrice(item.price ,item.price_currency) }}
        </template>

        <template #item.stock_part.suppliers="{ item }">
          <ul v-if="item.stock_part.suppliers !== undefined">
            <li
              v-for="supplier in item.stock_part.suppliers"
              class="supplier"
              v-on:click="openViewSupplier(supplier)"
              v-bind:key="supplier.pk"
            >
              {{$filters.capitalize( supplier.name ) }}
            </li>
          </ul>
        </template>

        <template #item.stock_part.image="{ item }">
          <v-img
            :src="item.stock_part.image"
            max-height="32px"
            max-width="32px"
            contain
            @click="zoom(item.stock_part.image)"
          ></v-img>
        </template>

        <template #top>
          <v-row justify="space-between" class="pa-2">
            <v-col cols="5" sm="4" md="3" class="py-0">
              <v-btn
                class="ma-2 pa-2"
                color="primary"
                :disabled="selected.length == 0"
                @click="createPurchaseBtn()"
              >
                <v-icon class="mr-1">mdi-cart-outline</v-icon>
                {{$filters.capitalize( $t("purchase_view.actions.create") ) }}
              </v-btn>
            </v-col>
            <v-col cols="5" sm="4" md="3" class="py-0" align="right">
              <v-btn
                class="ma-2 pa-2"
                color="primary"
                @click="createPurchaseLineBtn()"
              >
                <v-icon class="mr-1">mdi-cart-plus</v-icon>
                {{$filters.capitalize( $t("purchase_view.actions.add_line") ) }}
              </v-btn>
            </v-col>
          </v-row>
        </template>

        <template #item.actions="{ item }">
          <v-tooltip location="top"  :text="$filters.capitalize( $t('actions.edit'))">
            <template v-slot:activator="{ props }">
              <v-icon
                small
                v-bind="props"
                class="mr-2"
                @click="editPurchaseLineBtn(item)">
                mdi-pencil
              </v-icon>
            </template>
          </v-tooltip>

          <v-tooltip location="top" :text="$filters.capitalize( $t('actions.delete') )">
            <template v-slot:activator="{ props }">
              <v-icon
                v-show="isFromStaff"
                small
                v-bind="props"
                class="mr-2"
                @click="deletePLItemBtn(item.pk)">
                mdi-delete
              </v-icon>
            </template>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <v-card class="mx-auto my-3">
      <v-toolbar :flat='true' color="white" elevation="1">
        <v-spacer></v-spacer>

        <v-toolbar-title style="font-weight: bolder"
          >{{$filters.capitalize( $t(`purchase_view.label`, 1) ) }} &mdash;
          {{$filters.capitalize( $t(`purchase_view.title.purchase`, 10) ) }}
        </v-toolbar-title>

        <v-spacer></v-spacer>
      </v-toolbar>

      <v-data-table
        :headers="headersPurchases"
        :items="purchase_items"
        :row-props="bgClass"
        item-value="pk"
        :options.sync="optionsPurchases"
        :server-items-length="pageSizePurchases"
        :no-data-text="$filters.capitalize($t('purchase_view.no_data_text') )"
        :items-per-page-options='footerProps["items-per-page-options"]'
        :items-per-page-text='footerProps["items-per-page-text"]'
        elevation="2"
        :search="searchText"
      >
        <template #item.ordered_at="{ item }">
          {{$filters.formatDate( item.ordered_at ) }}
        </template>
        <template #item.received_at="{ item }">
          {{$filters.formatDate( item.received_at ) }}
        </template>
        <template #item.last_state_at="{ item }">
          {{$filters.formatDate( item.last_state_at ) }}
        </template>

        <template #item.last_state="{ item }">
          <v-tooltip location="top" :text="($t('purchases.states.initialized')+' : '+$filters.formatDate( item.created_at ))">
            <template #activator="{ props }">
              <v-icon
                v-bind="props"
                color="#0071b5"
                :class="last_state_icon_class(item, 1)">
                mdi-creation
              </v-icon>
            </template>
          </v-tooltip>
          <v-tooltip location="top" :text="($t('purchases.states.ordered')+': '+$filters.formatDate( item.ordered_at ))">
            <template #activator="{ props }">
              <v-icon
                v-bind="props"
                color="#0093b7"
                :class="last_state_icon_class(item, 2)"
                style="position: relative">
                mdi-timer-sand
              </v-icon>
            </template>
          </v-tooltip>
          <v-tooltip location="top" :text="($t('purchases.states.quoted')+': '+
          $filters.formatDate( item.quoted_at ))">
            <template #activator="{ props }">
              <v-icon
                v-bind="props"
                color="#039890"
                :class="last_state_icon_class(item, 3)"
                style="position: relative">
                mdi-cash-multiple
              </v-icon>
            </template>
          </v-tooltip>
          <v-tooltip location="top" :text="($t('purchases.states.paid')+': '+
          $filters.formatDate( item.paid_at ))">
            <template #activator="{ props }">
              <v-icon
                v-bind="props"
                color="#059e72"
                :class="last_state_icon_class(item, 4)"
                style="position: relative">
                mdi-clipboard-text-clock-outline
              </v-icon>
            </template>
          </v-tooltip>
          <v-tooltip location="top" :text="($t('purchases.states.sent')+': '+
          $filters.formatDate( item.sent_at ))">
            <template #activator="{ props }">
              <v-icon
                v-bind="props"
                color="#08a34d"
                :class="last_state_icon_class(item, 5)"
                style="position: relative">
                mdi-truck-fast
              </v-icon>
            </template>
            <span
              >{{ $t("purchases.states.sent") }}:
              {{$filters.formatDate( item.sent_at ) }}</span
            >
          </v-tooltip>
          <v-tooltip location="top" :text="($t('purchases.states.received')+': '+
              $filters.formatDate( item.received_at ))">
            <template #activator="{ props }">
              <v-icon
                v-bind="props"
                color="#0db000"
                :class="last_state_icon_class(item, 6)"
                style="position: relative">
                mdi-inbox-arrow-down
              </v-icon>
            </template>
          </v-tooltip>
        </template>

        <template #item.lines="{ item }">
          <ul v-if="item.lines !== undefined">
            <li v-for="line in item.lines" v-bind:key="line.pk">
              <span
                v-if="
                  ($store.getters.language === 'en' &&
                    line.stock_part.name_en !== '') ||
                  ($store.getters.language === 'fr' &&
                    line.stock_part.name === '')
                "
              >
                {{ line.quantity }}
                {{$filters.unityFilter( line.stock_part.unity ) }} &times;
                {{$filters.capitalize( line.stock_part.name_en ) }} &ndash;
                {{$filters.capitalize( line.stock_part.ref ) }}
              </span>
              <span v-else>
                {{ line.quantity }}
                {{$filters.unityFilter( line.stock_part.unity ) }} &times;
                {{$filters.capitalize( line.stock_part.name ) }} &ndash;
                {{$filters.capitalize( line.stock_part.ref ) }}
              </span>
            </li>
          </ul>
        </template>

        <template #item.actions="{ item }">
          <v-tooltip location="top"  :text="$filters.capitalize( $t('actions.edit'))">
            <template #activator="{ props }">
              <v-icon
                small
                v-bind="props"
                class="mr-2"
                @click="editPurchaseBtn(item)">
                mdi-pencil
              </v-icon>
            </template>
          </v-tooltip>

          <v-tooltip location="top" :text="$filters.capitalize(
              $t('purchase_view.actions.update_state') )">
            <template #activator="{ props }">
              <v-icon small v-bind="props" class="mr-2" @click="updateStateBtn(item)">
                mdi-state-machine
              </v-icon>
            </template>
          </v-tooltip>

          <v-tooltip location="top" :text="$filters.capitalize( $t('purchase_view.actions.received') )">
            <template #activator="{ props }">
              <v-icon
                small
                v-bind="props"
                class="mr-2"
                @click="receivedPurchaseBtn(item)">
                mdi-file-cabinet
              </v-icon>
            </template>
          </v-tooltip>

          <v-tooltip location="top" :text="$filters.capitalize( $t('purchase_view.actions.cancel') )">
            <template #activator="{ props }">
              <v-icon small v-bind="props" class="mr-2" @click="cancelBtn(item)">
                mdi-delete-empty-outline
              </v-icon>
            </template>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <v-card class="mx-auto my-3">
      <v-toolbar :flat='true' color="white" elevation="1">
        <v-spacer></v-spacer>

        <v-toolbar-title style="font-weight: bolder"
          >{{$filters.capitalize( $t(`purchase_view.label`, 1) ) }} &mdash;
          {{$filters.capitalize( $t(`purchase_view.title.archive`, 10) ) }}
        </v-toolbar-title>

        <v-spacer></v-spacer>
      </v-toolbar>

      <v-data-table
        :headers="headersArchives"
        :items="archived_items"
        item-key="pk"
        :options.sync="optionsArchives"
        :server-items-length="pageSizeArchives"
        :no-data-text="$filters.capitalize($t('purchase_view.no_data_text') )"
        :items-per-page-options='footerProps["items-per-page-options"]'
        :items-per-page-text='footerProps["items-per-page-text"]'
        elevation="2"
        :search="searchText"
      >
        <template v-slot:item.ordered_at="{ item }">
          {{$filters.formatDate( item.ordered_at ) }}
        </template>
        <template v-slot:item.received_at="{ item }">
          {{$filters.formatDate( item.received_at ) }}
        </template>

        <template v-slot:item.lines="{ item }">
          <ul v-if="item.lines !== undefined">
            <li v-for="line in item.lines" v-bind:key="line.pk">
              <span
                v-if="
                  ($store.getters.language === 'en' &&
                    line.stock_part.name_en !== '') ||
                  ($store.getters.language === 'fr' &&
                    line.stock_part.name === '')
                "
              >
                {{ line.quantity }}
                {{$filters.unityFilter( line.stock_part.unity ) }} &times;
                {{$filters.capitalize( line.stock_part.name_en ) }} &ndash;
                {{$filters.capitalize( line.stock_part.ref ) }}
              </span>
              <span v-else>
                {{ line.quantity }}
                {{$filters.unityFilter( line.stock_part.unity ) }} &times;
                {{$filters.capitalize( line.stock_part.name ) }} &ndash;
                {{$filters.capitalize( line.stock_part.ref ) }}
              </span>
            </li>
          </ul>
        </template>
      </v-data-table>
    </v-card>
</v-container>
</template>

<script>
import moment from "moment";
import {
  capitalize,
  isNone,
  slicyRemove,
  slicyUpdate,
  alertPopup,
} from "@/functions.js";
import utils from "@/mixins/utils";
import EditDialog from "@/components/EditDialog";
import SimpleDialog from "@/components/SimpleDialog";
import SupplierPrez from "@/components/prez/SupplierPrez";
import MenuStock from "@/components/MenuStock_old";
import PurchaseLineForm from "@/components/PurchaseLineForm";
import PurchaseLineFormSimplified from "@/components/PurchaseLineFormSimplified";
import PurchaseForm from "@/components/PurchaseForm";
import ReceivedPurchaseForm from "@/components/ReceivedPurchaseForm";

export default {
  mixins: [utils],

  components: {
    EditDialog: EditDialog,
    SimpleDialog: SimpleDialog,
    SupplierPrez: SupplierPrez,
    MenuStock: MenuStock,
    PurchaseLineForm: PurchaseLineForm,
    PurchaseLineFormSimplified: PurchaseLineFormSimplified,
    PurchaseForm: PurchaseForm,
    ReceivedPurchaseForm: ReceivedPurchaseForm,
  },

  computed: {
    isGrouped() {
      return this.purchase_line_items.some(item => item.stock_part && item.stock_part.supplier_names);
    },
    purchases_edit: function () {
      return this.$store.state["purchases"].edit;
    },
    lines_edit: function () {
      return this.$store.state["purchase_lines"].edit;
    },
    receivedPayload: function () {
      return this.$store.state[`purchases`].receivedPayload;
    },
    selected: {
      get: function () {
        return this.$store.state["purchase_lines"].selected;
      },
      set: function (value) {
        this.$store.dispatch("purchase_lines/setSelected", { items: value });
      },
    },
    ...utils.isFromStaff,
  },

  created() {
    this.stockage_id = parseInt(this.$route.query.stockage_id) || undefined;
    this.producer_type_id = parseInt(this.$route.query.producer_type_id) || undefined;
    this.loadPage();
  },

  methods: {
    loadPage() {
      if (!isNone(this.stockage_id) && !Number.isNaN(this.stockage_id)) {
        this.loadingStock = true;
        var options = this.genPaginationPayload(this.optionsPurchaseLines);
        var payload = {
          options: options,
          stockage_id: this.stockage_id,
          producer_type_id: this.producer_type_id,
        };
        if(this.$store.state.purchase_lines.isLoading) return
            this.$store
              .dispatch("purchase_lines/getCurrentNeeds", payload)
              .then((items) => {
                this.purchase_line_items = items.results;
                this.pageSizePurchaseLines = items.size;
              });
        if(this.$store.state.purchases.isLoading) return
          this.$store
            .dispatch("purchases/getOpenedPurchases", payload)
            .then((items) => {
              this.purchase_items = items.results;
              this.pageSizePurchases = items.size;
              this.loadingStock = false;
            });
          this.$store
            .dispatch("purchases/getArchivedPurchases", payload)
            .then((items) => {
              this.archived_items = items.results;
              this.pageSizeArchives = items.size;
            });
        const query = {
          ...this.$router.query,
          stockage_id: this.stockage_id,
          producer_type_id:this.producer_type_id,
        }
        this.$router.replace({query});
      }
    },

    menuChoose(value) {
      this.stockage_id = value.stockage_id;
      this.producer_type_id = value.producer_type_id;
      this.$store.dispatch(`purchase_lines/emptySelected`);
      this.optionsPurchaseLines.page = 1;
      this.optionsPurchases.page = 1;
      this.optionsArchives.page = 1;
      this.loadPage();
    },

    openViewSupplier(sup) {
      this.viewSupplierItem = sup;
      this.viewSupplier = true;
    },

    createPurchaseBtn() {
      this.$store.dispatch(`purchases/cleanupEdit`).then(() => {
        this.editPurchase_item = null;
        this.editPurchase = true;
      });
    },

    createPurchaseLineBtn() {
      this.lines_edit.stockage_id = this.stockage_id;
      this.$store
        .dispatch(`purchase_lines/updateEdit`, this.lines_edit)
        .then(() => {
          this.$emit("editItem");
          this.editPurchaseLine = true;
        });
    },

    cancelBtn(item) {
      const index = this.purchase_items.findIndex((i) => i.pk === item.pk);
      alertPopup(capitalize(this.$t("dialog.cancel_confirm"))).then(
        (result) => {
          if (result && this.purchase_items.splice(index, 1)) {
            this.$store
              .dispatch(`purchases/canceled`, item.pk)
              .then((lines) => {
                slicyRemove(this.purchase_items, item);
                for (var i in lines) {
                  slicyUpdate(this.purchase_line_items, lines[i]);
                }
              });
          }
        },
      );
    },

    editPurchaseLineBtn(item) {
      this.$store.dispatch("stock_parts/initSearch", {
        item_ids: item.stock_part_id,
      });
      this.$store.dispatch(`purchase_lines/updateEdit`, item).then(() => {
        this.$emit("editItem");
        this.editPurchaseLine = true;
      });
    },

    editPurchaseBtn(item) {
      this.$store.dispatch(`purchases/updateEdit`, item).then(() => {
        this.$emit("editItem");
        this.editPurchase_item = item;
        this.editPurchase = true;
      });
    },

    receivedPurchaseBtn(item) {
      this.$store.dispatch(`purchases/getLines`, item).then(() => {
        this.$emit("editItem");
        this.receivedPurchase = true;
      });
    },

    updateStateBtn(item) {
      this.$store.dispatch(`purchases/updateEdit`, item).then(() => {
        this.$emit("editItem");
        this.editPurchase_item = item;
        this.updateState = true;
      });
    },

    savePurchaseLineEvent() {
      if (!isNone(this.lines_edit.id)) return;

      if (isNone(this.lines_edit.stock_part_id)) { 
        this.lines_edit.stock_part_id = this.lines_edit.stock_part.pk;
      }
      delete this.lines_edit.stock_part;
      if (isNone(this.lines_edit.stockage_id)) { 
        this.lines_edit.stockage_id = this.lines_edit.stockage.pk;
      }
      delete this.lines_edit.stockage;
      let isNewLine = this.lines_edit.pk? false : true;
      return this.saveFullPurchaseLine()
        .then((item) => {
          if (isNewLine) {
            this.purchase_line_items.unshift(item);
          } else  {
            this.purchase_line_items = this.purchase_line_items.map(obj => obj.pk === item.pk ? item : obj)
          }
          this.$refs.editDialogPurchaseLine.closeDialog();
        });
    },

    saveFullPurchaseLine() {
      return this.$store.dispatch('purchase_lines/saveFullPurchaseLine', this.lines_edit);
    },


    savePurchaseEvent() {
      if (isNone(this.purchases_edit.pk)) {
        // NEW
        return this.$store
          .dispatch(`purchases/addItem`, this.purchases_edit)
          .then((item) => {
            const selected_ = this.selected.map((i) => {
              return { pk: i.pk, quantity: i.quantity , price:i.price, price_currency:i.price_currency};
            });
            this.$store
              .dispatch(`purchases/add_lines`, {
                pk: item.pk,
                items: selected_,
              })
              .then((item_) => {
                for (var i in this.selected) {
                  slicyRemove(this.purchase_line_items, this.selected[i]);
                }
                this.$store.dispatch(`purchase_lines/emptySelected`);
                slicyUpdate(this.purchase_items, item_);
                this.$refs.editDialogPurchase.closeDialog();
              })
              .catch((error) => {
                this.purchases_edit.pk = undefined;
                this.$store.dispatch(`purchases/deleteItem`, item.pk);
                this.$store.dispatch(`snackbar/showError`, error);
              });
          });
      } else {
        // UPDATE
        return this.$store
          .dispatch(`purchases/editItem`, this.purchases_edit)
          .then(() => {
            slicyUpdate(this.purchase_items, this.purchases_edit);
            this.$refs.editDialogPurchase.closeDialog();
          });
      }
    },

    saveReceivedPurchaseEvent() {
      var is_missing = [];
      for (var i = 0; i < this.receivedPayload.length; i++) {
        var line = this.receivedPayload[i];
        if (line.units != null) {
          for (let j = 0; j < line.units.length; j++) {
            if (line.units[j].serial != null && line.units[j].place_id == null)
              is_missing.push(
                this.$t("purchase_view.missing.place", { line: i + 1 }),
              );
          }
        } else {
          if (line.r_qty == null) {
            is_missing.push(
              this.$t("purchase_view.missing.quantity", { line: i + 1 }),
            );
          }
          if (line.r_qty > 0 && line.place_id == null) {
            is_missing.push(
              this.$t("purchase_view.missing.place", { line: i + 1 }),
            );
          }
        }
      }

      if (is_missing.length > 0) {
        this.$store.dispatch(
          "snackbar/showWarning",
          this.$t("purchase_view.missing.sentence", {
            slice: is_missing.join(", "),
          }),
        );
        return false;
      }

      return this.$store.dispatch(`purchases/received`).then((item) => {
        if (item.received_at) slicyRemove(this.purchase_items, item);
        this.$refs.receivedDialogPurchase.closeDialog();
      });
    },

    saveUpdateStateEvent() {
      return this.$store
        .dispatch(`purchases/editItem`, this.purchases_edit)
        .then(() => {
          slicyUpdate(this.purchase_items, this.purchases_edit);
          this.$refs.updateStateDialogPurchase.closeDialog();
        });
    },

    cleanupPurchaseLineDialog() {
      this.editPurchaseLine = false;
      this.$store.dispatch(
        `purchase_lines/updateEdit`,
        this.$store.state["purchase_lines"].struct,
      );
    },

    cleanupPurchaseDialog() {
      this.editPurchase = false;
      this.$store.dispatch(
        `purchases/updateEdit`,
        this.$store.state["purchases"].struct,
      );
    },

    cleanupViewSupplierDialog() {
      this.viewSupplier = false;
    },

    cleanupReceivedPurchaseDialog() {
      this.receivedPurchase = false;
    },

    cleanupUpdateStateDialog() {
      this.updateState = false;
      this.$store.dispatch(
        `purchases/updateEdit`,
        this.$store.state["purchases"].struct,
      );
    },

    deletePLItemBtn(item_id) {
      var index = this.purchase_line_items.findIndex((i) => i.pk === item_id);
      alertPopup(this.$t("dialog.delete_confirm")).then((result) => {
        if (result && this.purchase_line_items.splice(index, 1)) {
          this.$store.dispatch(`purchase_lines/deleteItem`, item_id);
        }
      });
    },

    last_state_icon_class(item, ordinal) {
      if (
        (ordinal === 1 && isNone(item.created_at)) ||
        (ordinal === 2 && isNone(item.ordered_at)) ||
        (ordinal === 3 && isNone(item.quoted_at)) ||
        (ordinal === 4 && isNone(item.paid_at)) ||
        (ordinal === 5 && isNone(item.sent_at)) ||
        (ordinal === 6 && isNone(item.received_at))
      ) {
        return "last_state_icon invisible";
      }
      return "last_state_icon";
    },

    switchIsLocallyBought(item) {
      this.$store.dispatch(`purchase_lines/switchIsLocallyBought`, item);
    },

    zoom(url) {
      // Zoom on this image
      this.imageInLarge = true;
      this.imageInLargeSrc = url;
    },

    bgClass(rowData) {
      // Compute bg classes (applied on each data row)
      var classes = [];
      let item = rowData.item
      if (!isNone(item.quantity) && item.quantity !== 0.0) {
        // Purchase line
        // Lateness
        if (moment(item.created_at) < this.limit_10) {
          classes.push("created_late");
        }
      } else if (isNone(item.ordered_at)) {
        // Actual purchase step
        classes.push("ordered_state");
      } else if (!isNone(item.ordered_at)) {
        // Lateness purchase
        var delay = item.delay;
        if (isNone(delay)) {
          delay = 1;
        }
        if (moment(item.ordered_at).add(delay, "weeks") < moment()) {
          classes.push("ordered_state");
        }
      }
      return {class: classes.join(" ")};
    },
  },

  watch: {
    optionsPurchaseLines: {
      handler() {
        this.loadPage();
      },
      deep: true,
    },
    optionsPurchases: {
      handler() {
        this.loadPage();
      },
      deep: true,
    },
    optionsArchives: {
      handler() {
        this.loadPage();
      },
      deep: true,
    },
  },

  data() {
    return {
      searchText: "",
      editPurchaseLine: false,
      editPurchase: false,
      editPurchase_item: null,
      viewSupplier: false,
      viewSupplierItem: false,
      receivedPurchase: false,
      updateState: false,
      purchase_line_items: [],
      purchase_items: [],
      archived_items: [],
      actionEdit: false,
      headersPurchaseLines: [
        {
          title: "",
          key: "data-table-group",
          width: "0px",
          align: "center",
          sortable: false
        },
        { title: this.$t("stock_parts.label"), key: "stock_part.name" },
        { title: this.$t("stock_parts.ref"), key: "stock_part.ref" },
        {
          title: this.$t("purchase_lines.is_locally_bought"),
          key: "is_locally_bought",
        },
        { title: this.$t("purchase_lines.stock"), key: "current_stock" },
        {
          title: this.$t("stock_parts.min_quantity"),
          key: "min_quantity",
        },
        {
          title: this.$t("stock_parts.max_quantity"),
          key: "max_quantity",
        },
        {
          title: this.$t("purchase_lines.created_at"),
          key: "created_at",
        },
        {
          title: this.$t("stock_parts.image"),
          key: "stock_part.image",
          align: " d-none d-md-table-cell",
        },
        {
          title: this.$t("suppliers.label"),
          key: "stock_part.suppliers",
          align: " d-none d-md-table-cell",
        },
        { title: this.$t("purchase_lines.price"), key: "price" },
        { title: this.$t("units.label"), key: "stock_part.unity" },
        {
          title: this.$t("actions.label"),
          key: "actions",
          sortable: false,
        },
      ],
      optionsPurchaseLines: {},
      pageSizePurchaseLines: undefined,
      headersPurchases: [
        { title: this.$t("purchases.lines"), key: "lines" },
        { title: this.$t("purchases.delay"), key: "delay" },
        { title: this.$t("purchases.comment"), key: "comment" },
        // { title: this.$t("stockages.label"), key: "stockage.address" },
        {
          title: this.$t("purchases.last_state_at"),
          key: "last_state_at",
        },
        { title: this.$t("purchases.last_state"), key: "last_state" },
        {
          title: this.$t("actions.label"),
          key: "actions",
          sortable: false,
        },
      ],
      headersArchives: [
        { title: this.$t("purchases.lines"), key: "lines" },
        { title: this.$t("purchases.comment"), key: "comment" },
        // { title: this.$t("stockages.label"), key: "stockage.address" },
        { title: this.$t("purchases.ordered_at"), key: "ordered_at" },
        { title: this.$t("purchases.received_at"), key: "received_at" },
      ],
      optionsPurchases: {},
      pageSizePurchases: undefined,
      optionsArchives: {},
      pageSizeArchives: undefined,
      footerProps: {
        "items-per-page-options": [20, 50, -1],
        "items-per-page-text": this.$t("rows_per_page"),
      },
      limit_10: moment().startOf("day").subtract(10, "days"),
      stockage_id: NaN,
      producer_type_id: NaN,
      loadingStock: false,
      imageInLarge: false,
      imageInLargeSrc: null,
    };
  },
};
</script>

<style>
tr.created_late td:nth-child(9) {
  background-color: #ef5350;
}
tr.ordered_state td:nth-child(5) {
  background-color: #efc8fe;
}

.imageInLargeDialog img {
  object-fit: contain;
}
td .v-image {
  cursor: pointer;
}

.last_state_icon {
  border-radius: 50%;
  border: 2px solid grey;
  padding: 1.1ex;
  background-color: white;
  box-shadow: -3px 1px rgba(0, 0, 0, 0.25);
}
.invisible {
  visibility: hidden;
}

li.supplier {
  cursor: pointer;
  text-decoration: underline;
}

</style>
