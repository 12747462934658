<template>
  <v-card class="mt-2">
    <div style="display: none">
      <EditDialog
          v-bind:model="editStockQuantity"
          v-bind:moduleName="'parts'"
          v-bind:btnName="''"
          v-bind:smallWidth="true"
          v-bind:formTitle="$t('edit_item')"
          @save="saveEditStockQuantity"
          @close="cleanupStockDialog"
          ref="editDialogStock"
          >
          <template v-slot:edit_content>
            <v-text-field
                v-model="consumables_edit.total"
                hide-details
                single-line
                type="number"
                />
          </template>
      </EditDialog>
      <EditDialog
          v-bind:model="addPlacementConsumable"
          v-bind:moduleName="'consumables'"
          v-bind:btnName="''"
          v-bind:smallWidth="true"
          v-bind:formTitle="$t('edit_item')"
          v-bind:dataValidation='validateConsumableForm'
          @save="savePlacement"
          @close="cleanupPlacement"
          >
          <template v-slot:edit_content>
            <ConsumableForm
                v-bind:askStockPart='false'
                v-bind:askStockage='false'
                v-bind:stockageId='edition.stockage_id'
                v-bind:stockPartId='edition.stock_part_id'
                ></ConsumableForm>
          </template>
      </EditDialog>

      <EditDialog
          v-bind:model="addPlacementUnit"
          v-bind:moduleName="'units'"
          v-bind:btnName="''"
          v-bind:smallWidth="true"
          v-bind:formTitle="$t('edit_item')"
          v-bind:dataValidation='validateUnitForm'
          @save="savePlacement"
          @close="cleanupPlacement"
          >
          <template v-slot:edit_content>
            <UnitForm
                v-bind:askStockPart='false'
                v-bind:askStockage='false'
                v-bind:stockageId='edition.stockage_id'
                v-bind:stockPartId='edition.stock_part_id'
                ></UnitForm>
          </template>
      </EditDialog>

    </div>
    <v-row no-gutters class="mb-0 pb-0">
      <v-col cols="12" class="mb-0 pb-0">
        <v-select
            v-model="edition.stockage_id"
            :items="$store.state.stockages.items"
            item-title="name"
            item-value="pk"
            :label="$filters.capitalize($t('stockages.label', 1) )"
            @update:modelValue="chooseStockageEvent"
            bg-color='blue'
            >
        </v-select>
      </v-col>
    </v-row>
    <v-row class="text-left pl-3 pb-4 mt-0" v-show="!edition.stockage_id">
      <v-col
          class="service_message"
          v-show="['', null, undefined].includes(edition.stockage_id)"
          >
          {{ $filters.capitalize($t("part_edition.select_stockage") ) }}
      </v-col>
    </v-row>
    <span v-show='edition.stockage_id'>
      <v-row class="mt-0 pt-0 mx-1">
        <v-col cols="6" class='mt-0 pt-0'>
          <v-text-field
              v-model="min_quantity"
              type="number"
              min="0"
              :label="$filters.capitalize($t('stock_parts.min_quantity') )"
              ></v-text-field>
        </v-col>
        <v-col cols="6" class='mt-0 pt-0'>
          <v-text-field
              v-model="max_quantity"
              type="number"
              min="0"
              :label="$filters.capitalize($t('stock_parts.max_quantity') )"
              ></v-text-field>
        </v-col>
      </v-row>
      <!-- EMPLACEMENTS/AFFECTATIONS -->
      <!-- UNITS -->
      <v-row :dense='true'>
        <v-data-table
            class="px-4"
            :headers="headers_units"
            :items="placesTotal"
            :loading="loadingPlaces"
            v-show="isUnit"
            :hide-default-footer="placesTotal.length < 10"
            density= 'compact'
            >
            <template v-slot:item.actions="{ item }">
              <v-tooltip location="top"  :text="$filters.capitalize( $t('actions.edit'))">
                <template v-slot:activator="{ props }">
                  <v-icon small v-bind="props" @click="viewUnit(item)">
                    mdi-eye-outline
                  </v-icon>
                </template>
              </v-tooltip>
            </template>
        </v-data-table>
        <!--CONSUMABLES -->
        <v-data-table
            class="px-4"
            :headers="headers_cons"
            :items="placesTotal"
            :loading="loadingPlaces"
            v-show="!isUnit"
            :hide-default-footer="placesTotal.length < 10"
            density= 'compact'
            >
            <template v-slot:item.actions="{item}">
              <v-icon
                  small
                  @click="openEditStockQuantity(item)"
                  >mdi-pencil</v-icon
                >
                <v-icon
                    v-show="isSquashable(item)"
                    small
                    @click="squashLineBtn(item)"
                    >mdi-delete</v-icon
                  >
            </template>
        </v-data-table>
      </v-row>
          <v-row class="px-4 mt-0 pb-4">
            <v-col>
              <v-btn
                  :disabled='!edition.stockage_id'
                  color="light-blue"
                  variant='tonal'
                  @click='addEmplacement()'
                  ><v-icon>mdi-plus</v-icon></v-btn>
            </v-col></v-row>

            <v-toolbar text color="white" class="pa-0 ma-0" elevation="0">
              <v-spacer></v-spacer>
              <v-btn color="success" @click="saveItem()">
                {{$filters.capitalize( $t("save") ) }}
              </v-btn>
              <v-spacer></v-spacer>
            </v-toolbar></span>
  </v-card>
</template>

<script>
  import {isNone} from '@/functions'
  import EditDialog from "@/components/EditDialog.vue";
  import ConsumableForm from '@/components/ConsumableForm';
  import UnitForm from '@/components/UnitForm';

  export default {
    components: {
      EditDialog,
      ConsumableForm,
      UnitForm,
    },
    props: {
      edition: { type: Object, required: true },
      showSPS: { type: Boolean, required: false },
    },
    computed: {
      consumables_edit: function () {
        return this.$store.state["consumables"].edit;
      },
      units_edit: function () {
        return this.$store.state["units"].edit;
      },
      isUnit() {
        return this.$store.state.stock_parts.edit.unity == "un" ? true : false; //using builtin function slows page down
      },
    },

    methods: {
    validateUnitForm() {
        let is_missing = [];
      if (isNone(this.units_edit.place_id) ||
      (typeof(this.units_edit.place_id)=='object' &&
        isNone(this.units_edit.place_id.pk))) {
        is_missing.push(this.$t("places.label", 1));
      }
      if ( isNone(this.units_edit.serial) &&
            isNone(this.units_edit.qr_code)) {
        is_missing.push(this.$t("part_view.missing.serial"))
      }
      if (is_missing.length > 0) {
        return this.$t("part_edition.missing.sentence", {
          slice: is_missing.join(", ")});
        }
      return undefined;
    },
    validateConsumableForm() {
        let is_missing = [];
      if (isNone(this.consumables_edit.place_id) ||
        (typeof(this.consumables_edit.place_id)=='object' &&
        isNone(this.consumables_edit.place_id.pk))) {
        is_missing.push(this.$t("places.label", 1));
      }
      if (isNone(this.consumables_edit.quantity)) {
        is_missing.push(this.$t("consumables.quantity"))
      }
      if (is_missing.length > 0) {
        return this.$t("part_edition.missing.sentence", {
          slice: is_missing.join(", ")});
        }
      return undefined;
    },
      addEmplacement(){
        this.$store.dispatch('places/cleanupEdit')
        if (this.isUnit) {
          this.$store.dispatch('units/updateEdit', this.units_edit).then(()=>{
            this.addPlacementUnit = true
          })
        } else {
          this.$store.dispatch('consumables/updateEdit', this.consumables_edit).then(()=>{
            this.addPlacementConsumable = true
          })
        }
      },
      savePlacement(){
        if (this.isUnit){
          let thing = this.units_edit.place_id
          if (thing && typeof(thing) == 'object') this.units_edit.place_id = thing.pk
          this.placesTotal.push(
            {
              place: this.units_edit.place_id,
              serial: this.units_edit.serial,
              qr_code: this.units_edit.qr_code,
            }
          );
        } else {
          this.placesTotal.push(
            {
              place:this.consumables_edit.place_id,
              total:this.consumables_edit.quantity
            }
          );
        };
        this.saveItem();
        this.cleanupPlacement();
      },
      cleanupPlacement(){
        this.$store.dispatch('units/cleanupEdit')
        this.$store.dispatch('consumables/cleanupEdit')
        this.addPlacementUnit = false
        this.addPlacementConsumable = false
      },
      saveStockEvent() {
        this.editStockQuantity = false;
        return this.$store.dispatch(`consumables/editItem`, this.consumables_edit).then(() => {
          this.$refs.editDialogStock.closeDialog();
        });
      },
      cleanupStockDialog() {
        this.editStockQuantity = false;
      },
      //HACK FIX VUETIFY https://github.com/logaretm/vee-validate/issues/4417
      convertPlacesStrToNum(placesTotalTmp) {
        if (Array.isArray(placesTotalTmp)) {
          placesTotalTmp.forEach(item => {
            if (item.total && !isNaN(item.total)) {
              item.total = parseInt(item.total, 10);
            }
          });
          return placesTotalTmp
        }
      },
      openEditStockQuantity(item) {
        this.editedIndex = this.placesTotal.indexOf(item)
        this.$store.dispatch('consumables/updateEdit', item).then(()=>{
          this.editStockQuantity = true
        })
      },
      saveEditStockQuantity(item) {
        if (this.editedIndex > -1) {
          Object.assign(this.placesTotal[this.editedIndex], this.consumables_edit)
        } else {
          this.placesTotal.push(this.consumables_edit)
        }
        this.saveItem()
      },
      isSquashable(item) {
        if (this.placesTotal.length <= 1) return false;
                     if (item.total > 0) return false;
        return true;
      },
      squashLineBtn(item) {
        this.$store
          .dispatch(`${this.moduleName}/squashPlace`, {
            place_id: item.place,
            stock_part_id: this.edition.stock_part_id,
          })
          .then((places) => {
            this.placesTotal = places;
            this.$store.dispatch(
              "snackbar/showSuccess",
              this.$t("snack.update_success"),
            );
          });
      },
      viewUnit(item) {
        if (!this.placesTotal.every(a=>a.id)){
          this.$store.dispatch(
            "snackbar/showError",
            this.$t("units.save_first")
          );
          return false
        }

        let query = {
          part: String(item.id).toString(),
        };
        this.$router.push({ name: "unit", query: query });
      },
      saveItem() {
        let full_part = {
          stockage_id: this.edition.stockage_id,
          stock_part_id: this.edition.stock_part_id,
          places: this.placesTotal,
          min_quantity: this.min_quantity,
          max_quantity: this.max_quantity,
        };
        full_part.places = this.convertPlacesStrToNum(full_part.places);
        this.$store
          .dispatch(`${this.moduleName}/saveFullPlace`, full_part)
          .then((n_p) => {
            this.placesTotal = n_p;
            this.$store.dispatch(
              "snackbar/showSuccess",
              this.$t("snack.update_success"),
            );
          });
        this.editStockQuantity = false;
        // this.$emit('saved');
      },
      chooseStockageEvent(value) {
        this.$emit("chooseStockage", value);
        this.$store.dispatch("places/getItems", {
          stockage_id: value,
          size: -1,
        });

        let options = {
          stock_part_id: this.edition.stock_part_id,
          stockage_id: value,
        };
        this.getPlaceTotals(options);
        this.getLimits(options);
      },
      getPlaceTotals(options) {
        this.loadingPlaces = true;
        this.$store.dispatch("parts/getPlaceTotals", options).then((places) => {
          this.placesTotal = places;
          this.loadingPlaces = false;
        });
      },
      getLimits(options) {
        this.$store
          .dispatch("stock_part_limits/getItems", options)
          .then((items) => {
            if (items.length > 0) {
              this.min_quantity = items[0].min_quantity;
              this.max_quantity = items[0].max_quantity;
            } else {
              this.min_quantity = 0;
              this.max_quantity = undefined;
            }
          });
      },
      loadAll(){
        let q_stockage_id = parseInt(this.$route.query.stockage_id) || undefined;
        let q_stock_part_id = parseInt(this.$route.query.stock_part_id) || undefined;

        if (isNone(q_stockage_id)) return;
        if (!isNone(q_stock_part_id)) {
          let options = {
            stock_part_id: q_stock_part_id,
            stockage_id: q_stockage_id,
          };
          this.getPlaceTotals(options);
          this.getLimits(options);
        };
      },
      },
    mounted() {
      this.loadAll();
      this.$store.dispatch('units/cleanupEdit');
      this.$store.dispatch('consumables/cleanupEdit');
    },
    watch: {
      showSPS() {
        // clear form when stock_part changes
        this.placesTotal = [];
        this.min_quantity = undefined;
        this.max_quantity = undefined;
        this.loadAll();
      },
    },
    data() {
      return {
        editStockQuantity: false,
        addPlacementConsumable: false,
        addPlacementUnit: false,
        moduleName: "parts",
        placesTotal: [],
        loadingPlaces: false,
        min_quantity: 0,
        max_quantity: undefined,
        // separate headers to allow separate primary keys
        headers_units: [
          {
            title: this.$t("parts.place", 1),
            key: "name",
            sortable: false,
          },
          {
            title: this.$t("units.serial", 1),
            key: "serial",
            sortable: false,
          },
          {
            title: this.$t("units.code", 1),
            key: "qr_code",
            sortable: false,
          },
          {
            title: "",
            key: "actions",
            sortable: false,
          },
        ],
        headers_cons: [
          {
            title: this.$t("parts.place", 10),
            key: "name",
            sortable: false,
          },
          {
            title: this.$t("consumables.stock"),
            key: "total",
            sortable: false,
          },
          {
            title: "",
            key: "actions",
            sortable: false,
          },
        ],
      };
    },
  }
</script>
<style>
.service_message {
  font-style: italic;
}
</style>
