<template>
  <v-container>
    <v-layout
        class="overflow-visible"
        style="height: 56px;"
        >
        <v-bottom-navigation
            :disabled='loading'
            v-model="source_choice"
            active
            grow
            color="primary"
            >
            <v-btn 
            v-for='choice in unit_source' 
            :value='choice.aff_kind' 
            :disabled='choice.disabled'
            >{{choice.text}}</v-btn>
        </v-bottom-navigation>
    </v-layout>
      <v-spacer></v-spacer>
      <v-select
          v-model="stockageSelected"
          v-show='showPlaceSelection'
          :items="$store.state.stockages.all"
          :item-props='stockageProps'
          :label="$filters.capitalize($t('stockages.label', 1))"
          @update:modelValue='updateStockage'
          variant="solo"
          return-object
          />
        <v-text-field
            v-model='searchText'
            prepend-inner-icon="mdi-magnify"
            />
          <!--- TODO: filter bit,to include:
            -  place (armoire/cabinet, etagere/shelf),
            - inc_otherproducer_types
            - part_category
            --->
            <v-data-table
                :headers='headers'
                :items="unitsToList"
                :loading='loading'
                :search='searchText'
                :custom-filter='customFilter'
                @click:row='itemSelected'
                >
                <template v-slot:loading>
                  <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
                </template>
            </v-data-table>
  </v-container>
</template>

<script>
  import { isNone, customFilter } from '@/functions.js'
  import { stockageProps } from "@/mixins/props"
  import { mapState } from 'vuex';

  export default {
    props: {
      edition: { type: Object, required: true }
    },
    methods: {
      customFilter,
      stockageProps,
      itemSelected(event, value){
        this.$emit('unitSelected', value.item)
      },
      updateStockage(val){
        this.edition.stockage_id = val.pk;
        this.updateList('ss');
      },
      removeAlreadyInReport(unitsList){
        const alreadyInReport = this.$store.state.intervention_reports.edition.affectations
        return unitsList.filter(item => {
          const isItemAlreadyInReport = alreadyInReport.some(existingItem =>
            existingItem.unit_id == item.unit_id
          );
          if (isItemAlreadyInReport) return false
          return true
        })
      },
      updateList(val){
        if (isNone(this.stockageSelected)) this.stockageSelected = this.edition.producer?.stockage_id
        let key = null;
        switch (val) {
          case 'ss':
            // #notProud
            if (typeof(this.stockageSelected)=='object') {
              this.stockageSelected = this.stockageSelected.pk
            }
            key = `stockage_id:${this.stockageSelected}`;
            if (key in this.$store.state.affectations.latest) {
              // get list from store if its already there
              let affList = this.$store.state.affectations.latest[key] || [];
              this.unitsToList = this.removeAlreadyInReport(affList)
            } else {
              // if not in store go get it from back  
              this.$store.dispatch(
                'affectations/getLatest', { 
                queryType: 'stockage_id',
                queryValue: this.stockageSelected
              }).then((resp)=>{
                this.unitsToList = this.removeAlreadyInReport(resp.results)
              })}
            this.showPlaceSelection = true;
            break;
          case 'p':
            key = `producer_id:${this.edition.producer_id}`;
            let affList =  this.$store.state.affectations.latest[key] || [];
            this.unitsToList = this.removeAlreadyInReport(affList)
            this.showPlaceSelection = false;
            break;
          case 'it':
            // for in-transit functionality
        }
        this.showList = true;
      },
    },
    mounted() {
      this.source_choice = 'ss';
    },
    watch: {
      source_choice(val){
        this.updateList(val);
      },
    },
    computed: {
      ...mapState({
        loading: state => state.affectations.isLoading,
      })
    },
    data() {
      return {
        searchText: '',
        showList: false,
        showPlaceSelection: false,
        source_choice: '',
        stockageSelected: undefined,
        unitsToList: [],
        unit_source: [
          { text: this.$t('affectations.status.producer'), aff_kind:'p'},
          { text: this.$t('affectations.status.in_transit'),
            aff_kind:'it', disabled: true},
          { text: this.$t('affectations.status.stock'), aff_kind:'ss'},
        ],
        headers: [
          {title:this.$t("part_categories.label"), key:'unit.stock_part.category.name'},
          {title:this.$t("stock_parts.name"), key:'unit.stock_part.name'},
          {title:this.$t("stock_parts.name_en"), key:'unit.stock_part.name_en'},
          {title:this.$t('stock_parts.ref'), key:'unit.stock_part.ref'},
          {title:this.$t('units.code'), key:'unit.qr_code'},
          {title:this.$t('units.serial'), key:'unit.serial'},
        ],
      };
    }
  };
</script>
