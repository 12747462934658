<template>
    <v-autocomplete
      v-model="producers"
      :items="$store.state.producers.all"
      item-title="display_name"
      item-value="pk"
      :label="$filters.capitalize($t('producers.label', 1))"
      :multiple="multiple"
      :filter="filterProducers"
      @update:modelValue="updateProducer"
      :clearable="true"
      variant="solo"
      class="mr-3"
      density= 'compact'
      hide-details
      return-object
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title>
            {{
              $filters.capitalize(
                $t("search_for", { noun: $t("producers.label", 1) })
              )
            }}
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-autocomplete>
</template>
<script>
import filters from "@/mixins/filters";
import { isNone } from "@/functions";

export default {
  mixins: [filters],

  props: {
    multiple: {type:Boolean, default:false, require:false}
  },
  methods: {
    updateProducer(item) {
      this.$emit("updateProducer", item);
    },
  },
  created(){
    const temp = this.$store.getters.dataEdit('producers');
    this.producers = temp.pk ? { ...temp } : null;
  },
  data() {
    return {
      producers:null,
    };
  },
};
</script>
