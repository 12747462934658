<template>
    <v-card color="white" class="flat px-2 mt-2" height="auto">
      <v-container @keyup.enter="handleEnterKey" tabindex="0">
      <v-row class='pt-4 d-flex flex-wrap'>
        <v-col>
          <v-text-field
            v-model="searchStockPartName"
            prepend-inner-icon='mdi-text-box-search'
            single-line
            hide-details
            :clearable="true"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
        <v-btn
            block
            color="grey"
            id='filter-menu'
            icon='mdi-filter'
            class="text-right"
          >
        </v-btn>
        <v-menu
            location='bottom'
            activator='#filter-menu'
            :close-on-content-click='false'
            max-width='350'
            class='px-3'
          >
          <v-list class="d-flex flex-column px-2">
            <v-list-subheader
                color='blue'
                block
                :title='this.$t("generic.filter").toUpperCase()'
              >
            </v-list-subheader>
            <v-list-item>
          <v-checkbox
              v-model='isUnit'
              :label='$filters.capitalize(this.$t("parts.only_units"))'
            >
          </v-checkbox>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
          <v-autocomplete
            v-model="edition.producer_type_id"
            style = "{width:350px}"
            :items="$store.state.producer_types.items"
            item-title="name"
            item-value="pk"
            :custom-filter='customFilter'
            :label="$filters.capitalize($t('producer_types.label', 1) )"
            :loading="$store.state.producer_types.isLoading"
            variant="solo"
            outlined
            :clearable="true"
            return-object
            @update:modelValue="chooseProdTypeEvent"
          >
          </v-autocomplete>
            </v-list-item>
            <v-list-item>
          <v-autocomplete
            v-model="edition.part_category_id"
            style = "{width:350px}"
            :items="$store.state.part_categories.items"
            :item-props="categoryProps"
            :label="$filters.capitalize($t('part_categories.label') )"
            :loading="$store.state.part_categories.isLoading"
            :custom-filter='customFilter'
            variant="solo"
            :clearable="true"
            @update:modelValue="choosePartCategoryEvent"
          >
          <template #no-data>
                {{
                  $filters.capitalize(
                    $t("search_for", { noun: $t("part_categories.label", 1) })
                  )
                }}
          </template>
          </v-autocomplete>
            </v-list-item>
          </v-list>
        </v-menu>
        </v-col>
      </v-row>
      <v-row class='px-3'>
        <v-chip 
          v-for='f in selected_filters'
          >
          {{f.name}}
        </v-chip>
      </v-row>
      <!-- Search Button -->
      <v-row>
      <v-col>
        <v-btn
          color="primary"
          @click="updateStockPartList"
          variant="elevated"
          block
        >
          {{ $filters.capitalize($t('search')) }}
        </v-btn>
      </v-col>
      </v-row>
      </v-container>

      <v-data-table
        :headers="headers"
        :items="$store.state.stock_parts.items"
        :v-model="selectedStockPart"
        :no-data-text="$filters.capitalize($t('no_data_text') )"
        :no-results-text="$filters.capitalize($t('no_results_text') )"
        :loading="$store.state.stock_parts.isLoading"
        class='px-4'
        @click:row="chooseStockPartEvent"
        fixed-header
      >
        <template v-slot:item.unity="{ item }">
          {{ $filters.capitalize($filters.unityFilter(item.unity)) }}
        </template>

        <template v-slot:item.image="{ item }">
          <v-img
            :src="item.image"
            max-height="32px"
            max-width="32px"
            contain
          ></v-img>
        </template>

        <template v-slot:item.price="{ item }">
          {{ $filters.formatPrice(item.price, item.price_currency) }}
        </template>

        <template v-slot:item.suppliers="{ item }">
          <ul v-if="item.suppliers !== undefined">
            <li
              v-for="supplier in item.suppliers"
              class="supplier"
              v-bind:key="supplier.pk"
            >
              {{ $filters.capitalize(supplier.name) }}
            </li>
          </ul>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
      <v-btn variant="tonal" @click = "newStockPartEvent">
        {{ $filters.capitalize($t("stock_parts.actions.new_item")) }}
      </v-btn>
      </v-card-actions>
    </v-card>
</template>

<script>
import { useDisplay } from "vuetify";
import filters from "@/mixins/filters";
import utils from "@/mixins/utils";
import { capitalize, isNone, customFilter } from "@/functions.js";

export default {
  mixins: [filters, utils],

  props: {
    edition: { type: Object, required: true },
  },

  computed: {
    selected_filters: function() {
      let r_ = []
      if (this.edition.producer_type_id) {
        const producerTypes = this.$store.state.producer_types.items
        let pt = producerTypes.find((type) => type.pk === this.edition.producer_type_id)
        if(pt) {
          pt['key'] = 'producer_type';
          r_.push(pt)
        }
      }
      if (this.edition.part_category_id) {
        const partCategories = this.$store.state.part_categories.items
        let pc = partCategories.find((pc) => pc.pk === this.edition.part_category_id)
        if(pc) {
          pc['key'] = 'part_category';
          r_.push(pc)
        }
      }
      if (this.isUnit) r_.push({key: 'is_unit', name:'only units'})
      return r_
    },
  },

  methods: {
    customFilter,
    categoryProps(item) {
      if (typeof item !== 'object' || item === null) return;
      const language = this.$store.getters.language;
      const nameBasedOnLang = language === 'en' && item.name_en ? item.name_en : item.name;
      let producerNames = language === 'en' ? 'Generic' : 'Génerique';
      if (Array.isArray(item.producer_types) && item.producer_types.length > 0) {
        producerNames = item.producer_types.map(producer => {
          return language === 'en' && producer.name_en ? producer.name_en : producer.name;
        }).join(', '); 
      }
      const newItemTitle = producerNames ? `${nameBasedOnLang} (${producerNames})` : nameBasedOnLang;
      const newItem = { ...item, title: newItemTitle, selection: newItemTitle, value: item.pk || 'error_item_props' };
      return newItem;
    },
    updateStockPartList() {
      if (this.$store.state.stock_parts.isLoading) return;
      let payload = {
        producer_type_id: this.edition.producer_type_id,
        category_id: this.edition.part_category_id,
        is_unit: this.isUnit,
        value: this.searchStockPartName,
        obsolete: false,
      }
      this.$store.dispatch("stock_parts/getItems", payload);
    },
    updateCats(producer_type) {
      if (!this.$store.state.part_categories.isLoading) {
        if (!isNone(producer_type)) {
          // avoid requests with id[]=undefined
          this.$store.dispatch("part_categories/getItemsFromProducerType", [
            producer_type.pk
          ]);
        } else {
          this.$store.dispatch("part_categories/getItems");
        }
      }
    },
    chooseProdTypeEvent(value) {
      this.$emit("chooseProdType", value);
      if (isNone(this.edition.part_category_id)) {
        this.updateCats(value);
      }
    },
    choosePartCategoryEvent(value) {
      this.$emit("choosePartCategory", value);
    },
    chooseStockPartEvent(event, value) {
      this.$store
        .dispatch(`${this.moduleName}/getItem`, value.item.pk)
        .then((item) => {
          this.$store.dispatch(`${this.moduleName}/updateEdit`, item);
          this.$emit("chooseStockPart", item);
        });
      this.searchStockPartName = "";
    },
    newStockPartEvent() {
      this.$store.dispatch("stock_parts/cleanupEdit");
      this.$emit("newStockPart");
    },
    handleEnterKey() {
      this.updateStockPartList();
    },
  },
  data() {
    return {
      moduleName: "stock_parts",
      searchStockPartName: "",
      selectedStockPart: undefined,
      isUnit: undefined,
      image: undefined,
      headers: [
        { title: this.$t("stock_parts.name"), key: "name" },
        { title: this.$t("stock_parts.name_en"), key: "name_en" },
        { title: this.$t("stock_parts.ref"), key: "ref" },
        { title: this.$t("stock_parts.price"), key: "price" },
        { title: this.$t("stock_parts.image"), key: "image" },
        {
          title: capitalize(this.$t("unity.label", 1)),
          key: "unity",
          align: " d-none d-md-table-cell"
        },
        {
          title: capitalize(this.$t("suppliers.label", 10)),
          key: "suppliers",
          align: " d-none d-md-table-cell"
        },
      ]
    };
  }
};
</script>
