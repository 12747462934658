<template>
  <v-card class="pa-2 mt-3">
    <StockPartInfo
 v-if="!editionActive"
    />
    <v-card v-if="editionActive" elevation="0">
      <v-form ref='form'>
      <v-row class="px-3 pt-3">
        <v-col cols="12" sm="6" md="4">
          <v-text-field
              v-model="$store.state.stock_parts.edit.name"
              :rules="[v => !!v || $filters.capitalize($t('generic.required') )]"
              :label="$filters.capitalize($t('part_categories.name') )"
              ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
              v-model="$store.state.stock_parts.edit.name_en"
              :label="$filters.capitalize($t('part_categories.name_en') )"
              ></v-text-field>
        </v-col>
        <v-col cols="12" sm="5" md="4">
          <v-text-field
              v-model="$store.state.stock_parts.edit.ref"
              :label="$filters.capitalize($t('stock_parts.ref_or_fab') )"
              ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="px-3">
        <v-col cols="12" sm="4" md="2">
          <v-select
              :disabled="!isItNewPart"
              v-model="$store.state.stock_parts.edit.unity"
              :items="$store.state.partItems"
              item-title="name"
              item-value="id"
              :label="$filters.capitalize($t('unity.label') )"
              :rules="[v => !!v || $filters.capitalize($t('generic.required') )]"
              variant="solo"
              >
          </v-select>
        </v-col>
        <v-col cols="8" sm="3" md="2">
          <v-text-field
              v-model.number="$store.state.stock_parts.edit.price"
              type="number"
              :label="$filters.capitalize($t('part_edition.price') )"
              ></v-text-field>
        </v-col>
        <v-col cols="4" sm="2" md="2">
          <SelectCurrency
              v-bind:currency="$store.state.stock_parts.edit.price_currency"
              @change="chooseCurrency"
              ></SelectCurrency>
        </v-col>
        <v-col cols="8" md="6">
          <v-autocomplete
              v-model="$store.state.stock_parts.edit.category_id"
              :items="$store.state.part_categories.items"
              :item-title='$store.getters.language === "en"? $filters.formatCategoryEn :$filters.formatCategory '
              item-value="pk"
              :label="$filters.capitalize($t('part_categories.label') )"
              :custom-filter="customFilter"
              :clearable="true"
              :rules="[v => !!v || $filters.capitalize($t('generic.required') )]"
              variant="solo"
              @click:clear='clearPartCat'
              @update:modelValue="choosePartCategoryEvent"
              >
              <template #no-data>
                {{
                $filters.capitalize(
                $t("search_for", { noun: $t("part_categories.label", 1) })
                )
                }}
              </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="px-3" justify="start">
        <v-col cols="6" sm="2" md="6">
          <v-autocomplete
            v-model="$store.state.stock_parts.edit.supplier_ids"
            :items="$store.state.suppliers.items"
            item-title="name"
            item-value="pk"
            :label="$filters.capitalize($t('suppliers.label', 10) )"
            v-model:search="autocompleteSearchSupplier"
            :loading="$store.state.suppliers.isSearching"
            :clearable="true"
            variant="solo"
            cache-items
            chips
            closable-chips
            persistent-hint
            :hint="$filters.capitalize($t('suppliers.label', 10) )"
            multiple
          >
          <template #no-data>
                  {{ $filters.capitalize(
                  $t("search_for", { noun: $t("suppliers.label", 10) })
                )
                  }}
            </template>
          </v-autocomplete>
        </v-col>

        <v-col cols="4" sm="4" md="2">
          <v-switch
              v-model="$store.state.stock_parts.edit.is_locally_bought"
              inset
              :label="
                      $store.state.stock_parts.edit.is_locally_bought == true
                      ? $t('stock_parts.is_locally_bought_.true')
                      : $t('stock_parts.is_locally_bought_.false')
                      "
              ></v-switch>
        </v-col>
        <v-col cols="4" sm="4" md="4" class="d-flex justify-center">
          <v-file-input
              ref='fileInput'
              v-model="image_file"
              v-on:update:modelValue="on_select_image"
              style="display: none"
              ></v-file-input>
          <v-img
              style="border: 1px dashed grey; cursor:pointer;"
              v-bind:src="image_src"
              placeholder="static/camera_off.png"
              aspect-ratio="1"
              max-height="210"
              max-width="280"
              contain
              @click="openFileDialog"
              />
        </v-col>
      </v-row>
      </v-form>
    </v-card>
    <div class="d-flex flex-wrap justify-center">
      <v-btn class="ma-2" color="warning" @click="clearStockPartEvent">{{ $filters.capitalize(
        $t("stock_parts.actions.choose_another_item"))
        }}
      </v-btn>
      <v-btn
          class="ma-2"
          v-show="!editionActive && !isItNewPart && isFromStaff"
          @click="activateEdition"
          >{{ $filters.capitalize($t("stock_parts.actions.edit")) }}
      </v-btn>
        <v-btn
            class="ma-2"
            v-show="editionActive && !isItNewPart"
            @click="resetStockPartChanges"
            >{{ $filters.capitalize($t("reset_changes")) }}
        </v-btn>
          <v-btn
              class="ma-2"
              color="success"
              v-show="editionActive && !isItNewPart"
              @click="saveStockPart"
              >{{ $filters.capitalize($t("save_changes")) }}
          </v-btn>
            <v-btn
                class="ma-2"
                color="success"
                v-show="isItNewPart"
                @click="saveStockPart"
                >{{ $filters.capitalize($t("stock_parts.actions.save_new_item")) }}
            </v-btn>
    </div>
  </v-card>
</template>

<script>
  import filters from "@/mixins/filters";
  import utils from "@/mixins/utils";
  import { isNone, customFilter } from "@/functions.js";
  import StockPartInfo from "@/components/StockPartInfo";
  import SelectCurrency from "@/components/SelectCurrency";

  export default {
    mixins: [filters, utils],

    components: { SelectCurrency, StockPartInfo },

    props: {
      edition: { type: Object, required: true },
      isItNewPart: { type: Boolean, required: false, default: false }
    },

    computed: {
      ...utils.isFromStaff,
      part_name: function() {
        if (this.$store.getters.language === "en") {
          return "name_en";
        }
        return "name";
      }
    },

    mounted() {
      if (this.$store.state.part_categories.items.length <= 0) {
        this.$store.dispatch('part_categories/getItems')
      }
      this.setTempImage();
      if (this.isItNewPart) {
        this.editionActive = true;
      }
    },

    methods: {
      customFilter,
      openFileDialog() {
        this.$refs.fileInput.$el.querySelector('input[type="file"]').click()
      },
      clearPartCat() {
        this.edition.part_category_id = undefined
      },
      setTempImage() {
        const infos = this.$store.state.stock_parts.edit.image_infos;
        if (!isNone(infos)) {
          var file = new File(
            [this.$store.state.stock_parts.edit.image],
            infos.name,
            {
              type: infos.type
            }
          );
          this.image_src = this.$store.state.stock_parts.edit.image;
          this.image_file = file;
        } else {
          this.image_file = undefined;
          this.image_src = "static/camera_off.png";
        }
      },
      async validate () {
        const { valid } = await this.$refs.form.validate()
      },
      saveStockPart() {
        this.validate()
        let is_missing = [];
        if (isNone(this.$store.state.stock_parts.edit.name)) {
          is_missing.push(this.$t("part_edition.missing.name"));
        }
        if (isNone(this.$store.state.stock_parts.edit.unity)) {
          is_missing.push(this.$t("part_edition.missing.unity"));
        }
        if (isNone(this.$store.state.stock_parts.edit.category_id)) {
          is_missing.push(this.$t("part_edition.missing.category"));
        }
        if (is_missing.length > 0) {
          this.$store.dispatch(
            "snackbar/showWarning",
            this.$t("part_edition.missing.sentence", {
              slice: is_missing.join(", ")
            })
          );
          return false;
        }
        let item = this.$store.state.stock_parts.edit;
        let spread_item = {
          ...(delete item.image && delete item.image_infos && item)
        };
        if (this.edition.stock_part_id) {
          this.$store
            .dispatch(`${this.moduleName}/editItem`, spread_item)
            .then((updatedItem) => {
              this.$emit("chooseStockPart", updatedItem);
              this.dealWithTempImage(updatedItem);
            });
        } else {
          this.$store
            .dispatch(`${this.moduleName}/addItem`, spread_item)
            .then((newItem) => {
              this.$emit("chooseStockPart", newItem);
              this.dealWithTempImage(newItem);
            });
        }
        this.editionActive = false;
      },
      chooseCurrency(value) {
        this.$store.state.stock_parts.edit.price_currency = value;
      },
      dealWithTempImage(item) {
        if (isNone(this.image_file) && isNone(item.image)) {
            this.$store.dispatch(
              "snackbar/showSuccess", this.$t("snack.update_success")
            );
        } else if (isNone(this.image_file) && !isNone(item.image)) {
          this.deleteImage(item.pk);
        } else if (this.image_src.startsWith("blob:")) {
          // a new File has been generated
          // TODO? : remove prev image if one already there
          this.sendImage({
            item_id: item.pk,
            file: this.image_file,
            file_name: this.image_src
          }).then(() => {
            this.$store.dispatch(
              "snackbar/showSuccess", this.$t("snack.update_success")
            );
            this.resetStockPartChanges();
          });
        } else {
          this.$store.dispatch(
             "snackbar/showSuccess", this.$t("snack.update_success")
          );
        }
      },
      resetStockPartChanges() {
        this.$store
          .dispatch(`${this.moduleName}/getItem`, this.edition.stock_part_id)
          .then((item) => {
            this.$store.dispatch(`${this.moduleName}/updateEdit`, item);
            this.setTempImage();
          });
      },
      updateCats(producer_type_id) {
        if (!isNone(producer_type_id)) {
          //avoid requests with id[]=undefined
          this.$store
            .dispatch("part_categories/getItemsFromProducerType", [
              producer_type_id
            ])
            .then(() => {
              this.disableProdCat = false;
            });
        } else {
          this.$store.dispatch("part_categories/getItems").then(() => {
            this.disableProdCat = false;
          });
        }
      },
      chooseProdTypeEvent(value) {
        if (!this.isItNewPart) {
          if (this.part_category_id === undefined) {
            this.$emit("chooseProdType", value);
          }
        }
        this.updateCats(value);
        if (!isNone(this.part_category_id)) {
          this.edition.part_category_id = undefined;
        }
      },
      choosePartCategoryEvent(value) {
        this.$emit("stock_parts/updateEdit", { category_id: value });
        if (!isNone(value)) {
          this.$emit("choosePartCategory", value);
        }
      },
      clearStockPartEvent() {
        this.editionActive = false;
        this.$emit("clearStockPart");
      },
      activateEdition() {
        if (this.$store.state.suppliers.search.length <= 0 ) {
          this.$store.dispatch(
            "suppliers/getItems",
            this.producerId
          ).then((items)=>{
            this.$store.dispatch("suppliers/setSearch",items)
          })
        }

        this.resetStockPartChanges();
        this.editionActive = true;
      },
      on_select_image(file) {
        if (file) {
          this.image_src = URL.createObjectURL(file);
          this.image = file;
          this.$store.dispatch(`${this.moduleName}/updateEdit`, {
            ...this.$store.state.stock_parts.edit,
            image: URL.createObjectURL(file),
            image_infos: this.image_file
          })
        }
      },

      deleteImage(item_id) {
        this.$store
          .dispatch(`${this.moduleName}/deleteFile`, item_id)
          .then(() => {
            this.image_src = "static/camera_off.png";
            this.image = undefined;
            this.$store.dispatch(
              "snackbar/showSuccess",
              this.$t("snack.image_removed")
            );
            this.resetStockPartChanges();
          });
      },

      sendImage({ item_id, file, file_name }) {
        return new Promise((resolve, reject) => {
          this.$store
            .dispatch(`${this.moduleName}/sendFile`, {
              pk: item_id,
              file: file,
              name: file_name
            })
            .then(() => {
              resolve();
            })
            .catch((error) => {
              this.$store.dispatch(
                "snackbar/showError",
                this.$t(`snack.send_file_error`, { msg: error })
              );
              reject(error);
            });
        });
      },
    },
    watch: {
      autocompleteSearchSupplier(val) {
        if (!this.$store.getters.canSearch("suppliers")) return;
        this.$store.dispatch("suppliers/searchItems", { value: val });
      },
      isItNewPart(val) {
        if (val) {
          this.editionActive = true;
          this.setTempImage();
        } else {
          this.editionActive = false;
        }
      },
      image_file(val) {
        if (!val) this.image_src = undefined;
      }
    },
    data() {
      return {
        moduleName: "stock_parts",
        autocompleteSearchSupplier: "",
        autocompleteSearchCategory: "",
        disableProdCat: true,
        editionActive: false,
        image: undefined,
        image_file: undefined,
        image_src: "static/camera_off.png",
        imageInLarge: false,
        imageInLargeSrc: null
      };
    }
  };
</script>
<style>
div .field-name {
  color: midnightblue;
}
li {
  margin-left: 1em;
}
</style>
