<template>
  <v-container>
    <v-autocomplete
        v-show="askStockage"
        v-model="stockageSelected"
        :items="$store.state.stockages.all"
        :item-props='stockageProps'
        :label="$filters.capitalize($t('stockages.label', 1))"
        :clearable="true"
        variant="solo"
        >
    </v-autocomplete>
      <v-autocomplete
          v-model="placeSelected"
          :items="availablePlaces"
          :item-props='placeProps'
          :loading='$store.state.places.isLoading'
          :disabled='$store.state.places.isLoading'
          :label="$filters.capitalize($t('places.label', 1))"
          :clearable="true"
          variant="solo"
          >
      </v-autocomplete>
  </v-container>
</template>
<script>
  import {isNone} from "@/functions.js";
  import { placeProps, stockageProps } from "@/mixins/props"

  export default {
    computed: {

      availablePlaces: function(){
        return this.$store.state.places.items
      }
    },
    props: {
      askStockage: {type: Boolean, required: false, default: true }
      // producerTypeId: {}, // groundwork for stockage filtering by p-type
    },
    methods:{
      placeProps,
      stockageProps,
      getPlaces(stockage){
        let stockage_id = this.getIdIfObject(stockage)
        if (this.arePlacesAlreadyLoaded(stockage_id)) return
        if (stockage_id){
          this.$store.dispatch("places/getItems", {
            stockage_id: stockage_id,
            size: -1,
          });
        };
      },
      getIdIfObject(obj) { // #notProud
        let _id = obj
        if (obj && typeof(obj)=='object') _id = obj.pk
        return _id
      },
      arePlacesAlreadyLoaded(stockage_id){
        if (this.availablePlaces.length <= 0) return false
        if ((this.availablePlaces).some(({ pk }) => pk == this.placeSelected?.pk)) return false;
        if ((this.availablePlaces).some(item => item.stockage_id != stockage_id)) return false;
        return true
      },
      isPlaceInStockage(place, stockage) {
        let stockage_id = this.getIdIfObject(stockage)
        if (this.placeSelected && typeof(this.placeSelected) == 'object') {
          if (this.placeSelected.stockage_id == stockage_id) return true
          else return false
        }
        let place_obj = this.availablePlaces.find(item => item.pk == this.placeSelected)
        if (place_obj && place_obj.stockage_id != stockage_id) return false
        else return true
      },
    },
    mounted(){
      this.placeSelected = this.$store.state.places.edit;
      this.stockageSelected = this.$store.state.stockages.edit;
    },
    unmounted(){
      this.$store.dispatch('stockages/cleanupEdit');
      this.$store.dispatch('places/cleanupEdit');
    },
    watch: {
      placeSelected(val){
        this.$emit("subFormUpdate", "ss", val);
      },
      stockageSelected(val){
        if (!this.isPlaceInStockage(this.placeSelected, val)) {
          this.placeSelected = undefined;
        }
        this.getPlaces(val)
      },
    },
    data() {
      return {
        stockageSelected: undefined,
        placeSelected: undefined,
      }
    },
  };
</script>
