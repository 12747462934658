<template>
  <v-container pa-0 ma-0>
    <v-row>
      <v-col cols="12">
        <v-autocomplete
            v-model="editedItem.category"
            :items="$store.state.part_categories.search"
            :item-props='categoryProps'
            @update:modelValue='updateCategory'
            @update:search='updateSearch'
            :label="$filters.capitalize($t('part_categories.label'))"
            v-model:search="autocompleteSearchCat"
            :loading="$store.state.part_categories.isSearching"
            :filter="filterPartCategory"
            :clearable="true"
            variant="solo"
            cache-items
            return-object
            >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{
                  $filters.capitalize(
                  $t("search_for", { noun: $t("part_categories.label", 1) })
                  )
                  }}
                </v-list-item-title>
              </v-list-item>
            </template>
        </v-autocomplete>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
    import filters from '@/mixins/filters';
  export default {
    mixins: [filters],
    computed: {
      editedItem: function () {
        return this.$store.getters.dataEdit(this.moduleName);
      },
    },
    methods: {
      updateCategory () {
        this.editedItem.category_id = this.editedItem.category?.pk;
      },
      updateSearch (val) {
        if (this.editedItem.category?.pk) return
        if (!this.$store.getters.canSearch("part_categories")) return;
        this.$store.dispatch("part_categories/searchItems", { value: val });
      },
      categoryProps (item) {
        if (typeof item !== 'object' || item === null || !('pk' in item)) {
          return {...item, title:''};
        }
        const language = this.$store.getters.language;
        const nameBasedOnLang = language === 'en' && item.name_en ? item.name_en : item.name;

        let producerNames = this.$filters.capitalize(this.$t('part_categories.generic'));

        if (Array.isArray(item.producer_types) && item.producer_types.length > 0) {
          producerNames = item.producer_types.map(producer => {
            return language === 'en' && producer.name_en ? producer.name_en : producer.name;
          }).join(', '); 
        }
        const newItemTitle = producerNames ? `${nameBasedOnLang} (${producerNames})` : nameBasedOnLang;
        const newItem = { ...item, title: newItemTitle, selection: newItemTitle, value: item.pk || 'error_item_props' };
        return newItem;
      },
    },
    data() {
      return {
        moduleName: "stock_parts",
        autocompleteSearchCat: undefined,
      };
    },
  };
</script>
