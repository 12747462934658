<template>
  <v-container class="pa-0 ma-0">
    <div style="display: none">
      <v-dialog
          v-model="showAffectationDialog"
          >
          <v-card>
            <UnitSelect
                v-bind:edition='editedItem'
                @unitSelected='newAffectation'
                @click:outside="cleanupAffectationDialog"
                >
            </UnitSelect>
          </v-card>
      </v-dialog>
      <EditDialog
          :model="showEditAffectation"
          :moduleName="'affectations'"
          :formTitle="formTitle"
          @save="saveAffectation"
          @close="cleanupAffectationDialog"
          >
          <!-- Refacto this bit -->
      <template v-slot:edit_content>
        <v-container>
          <v-row>
            <v-col>
              <div class='field-name'>{{ $filters.capitalize($t("part_categories.label")) }}</div>
              <p class="text-body-1 text--primary">
              {{ editedLine.unit.stock_part.category.name }}
              </p>
            </v-col>
            <v-col>
              <div class='field-name'>{{ $filters.capitalize($t("stock_parts.name")) }}</div>
              <p class="text-body-1 text--primary">
              {{ editedLine.unit.stock_part.name }} -- {{ editedLine.unit.stock_part.ref }}
              </p>
            </v-col>
            <v-col>
              <div class='field-name'>{{ $filters.capitalize($t("units.serial")) }}</div>
              <p class="text-body-1 text--primary">
              {{ editedLine.unit.serial }}
              </p>
            </v-col>
            <v-col>
              <div class='field-name'>{{ $filters.capitalize($t("units.code")) }}</div>
              <p class="text-body-1 text--primary">
              {{ editedLine.unit.qr_code }}
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <AffectationForm
                  :askDate="false"
                  :askKind="isNone(editedLine.producer_id)"
                  :askProducer="false"
                  :withProducer="isNone(editedLine.kind) || !isNone(editedLine.producer_id)"
                  @affKindChanged='affError=false'
                  />
            </v-col>
          </v-row>
          <v-alert v-if="affError" type="error" dismissible>
            {{$filters.capitalize($t('snack.no_kind'))}}
          </v-alert>
        </v-container>
      </template>
      </EditDialog>
    </div>
    <v-container>
      <v-row class="pa-0 ma-0">
        <v-col cols='12' class="subtitle-block">
          {{ $filters.capitalize($t("intervention_reports.affectation.add_or_remove_unit"))}}
        </v-col>
      </v-row>
      <v-row class="pa-0 ma-0">
        <v-col cols='12' class="px-0 mx-0 mt-0 pt-0">
          <v-table class="fill-width">
            <thead>
              <tr>
                <th>{{$t("stock_parts.ref")}}</th>
                <th>{{$t("units.serial")}}</th>
                <th>{{$t("units.code")}}</th>
                <th>{{$t('affectations.choose_type')}}</th>
                <th>{{$t('actions.label')}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(aff, index) in editedItem.affectations" :key="aff.pk">
                <td>{{aff.unit.stock_part.name}} - {{aff.unit.stock_part.ref}}</td>
                <td>{{aff.unit.serial}}</td>
                <td>{{aff.unit.qr_code}}</td>
                <td>{{ $filters.affectationKindFilter(aff.kind)}}</td>
                <td>
                  <v-icon class='mr-1' @click='deleteAffectation(index)'>mdi-delete</v-icon>
                </td></tr>
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <v-btn
                      icon='mdi mdi-plus'
                      class='my-2 ml-2'
                      color='primary-darken-1'
                      variant='elevated'
                      @click='openAffectation()'
                      ></v-btn>
                </td>
                <td>
                  <ScanCodeButton
                      @fetchedUnit="newAffectationFromQRCode"
                      />
                </td>
              </tr>
            </tfoot>
          </v-table>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
  import EditDialog from "@/components/EditDialog";
  import UnitSelect from "@/components/intervention_report/UnitSelect";
  import AffectationForm from "@/components/AffectationForm";
  import ScanCodeButton from '@/components/qrcode/ScanCodeButton'
  import { isNone } from "@/functions";

  export default {
    components: {
      EditDialog: EditDialog,
      UnitSelect: UnitSelect,
      AffectationForm: AffectationForm,
      ScanCodeButton: ScanCodeButton,
    },
    methods: {
      isNone,
      openAffectation(){
        this.showAffectationDialog = true;
      },
      newAffectationFromQRCode(unit){
        this.$store.dispatch('affectations/getItems', { unit_id:unit.pk }).then((affs)=>{
          if (affs.length>=1){
            this.newAffectation(affs[0])
          } else {
            // TODO: confirm no history can be assumed as unit to be installed
            let ref_for_aff = {
              unit: unit,
              unit_id: unit.pk
            }
            this.newAffectation(ref_for_aff);
          }
        })
      },
      newAffectation(aff_) {
        // aff_ being the last affectation / current state of unit
        this.editedLine = {
          date: this.editedItem.date,
          unit: aff_.unit,
          unit_id: aff_.unit_id,
        }
        if (aff_.kind && aff_.kind!='p') {
          // if unit was not in machine then this affectation is to the machine
          this.editedLine.kind = 'p'
          this.editedLine.id_ = this.editedItem.producer_id
          // these two are for displaying, only id_ is sent to back
          this.editedLine.producer_id = this.editedItem.producer_id
          this.editedLine.producer = this.editedItem.producer
        }
        this.$store.dispatch('affectations/updateEdit', this.editedLine)
        this.affError = false;
        this.initSubForms();
        this.showEditAffectation = true;
      },
      saveAffectation(){
        if (isNone(this.$store.state.affectations.edit.kind)) {
          this.affError = true;
          return
        }
        const index = this.editedItem.affectations.findIndex(existingItem => existingItem.unit_id === this.editedLine.unit_id);
        if (index !== -1) this.editedItem.affectations[index] = this.$store.state.affectations.edit
        else this.editedItem.affectations.push(this.$store.state.affectations.edit)
        this.showAffectationDialog = false;
        this.showEditAffectation = false;
      },
      deleteAffectation(index){
        this.editedItem.affectations.splice(index, 1)
      },
      cleanupAffectationDialog(){
        this.showEditAffectation = false;
        this.showAffectationDialog = false;
      },
      initSubForms(){
        if (isNone(this.$store.state.external_repairs.edit.stockage_id)) {
          this.$store.state.external_repairs.edit.stockage_id = this.editedItem.stockage_id;
        };
        if (isNone(this.$store.state.internal_repairs.edit.assigned_to_id)) {
          this.$store.state.internal_repairs.edit.assigned_to_id = this.$store.state.user.id 
        };
        if (isNone(this.$store.state.stockages.edit.pk)) {
          // TODO: get last place of unit or set to imprecise
          let id_ = this.editedItem.stockage_id
          let list_ = this.$store.state.stockages.all
          this.$store.state.stockages.edit = list_.find(s => s.pk == id_)
        }
        if (isNone(this.$store.state.producers.edit.pk)) {
          let id_ = this.editedItem.producer_id
          let list_ = this.$store.state.producers.all
          this.$store.state.producers.edit = list_.find(s => s.pk == id_)
        }
      },

    },
    computed: {
      editedItem: {
        get() {
          return this.$store.state[`${this.moduleName}`].edition;
        },
        set(obj) {
          this.$store.dispatch('intervention_reports/updateEdit', obj)
        },
      },
      formTitle: function () {
        if (isNone(this.editedLine?.pk)) return this.$t('affectations.creation_label')
        else return this.$t('affectations.edition_label')
      },
    },
    data() {
      return {
        moduleName: 'intervention_reports',
        showAffectationDialog: false,
        showEditAffectation: false,
        editedLine: undefined,
        affError: false,
      }
    },
  }
</script>
<style scoped>
.subtitle-block {
  background-color: #1976D2;
  color: white;
  display: inline-block; /* To keep it as a block with proper text wrap */
  font-weight: bold;
  font-size: 1.25rem; /* Increase font size for better readability */
  letter-spacing: 0.5px; /* Slightly space out letters for improved readability */
}
</style>
