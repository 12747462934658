<template>
  <v-row>
    <CategoryForm></CategoryForm>
    <v-col cols="12" sm="6" md="4">
      <v-text-field
          v-model="editedItem.name"
          :label="$filters.capitalize($t('stock_parts.name') )"
          ></v-text-field>
    </v-col>
    <v-col cols="12" sm="6" md="4">
      <v-text-field
          v-model="editedItem.name_en"
          :label="$filters.capitalize($t('stock_parts.name_en') )"
          ></v-text-field>
    </v-col>
    <v-col cols="12" sm="12" md="6">
      <v-text-field
          v-model="editedItem.ref"
          :label="$filters.capitalize($t('stock_parts.ref') )"
          ></v-text-field>
    </v-col>
    <v-col cols="12" sm="3" md="3">
      <v-select
          v-model="editedItem.unity"
          :items="unityList"
          item-title="name"
          item-value="id"
          :label="$filters.capitalize($t('unity.label', 1) )"
          variant="solo"
          >   <template v-slot:item="{ props, item }">
            <v-list-item
                v-bind="props"
                :disabled="item.raw.disabled"
                ></v-list-item>
      </template>
      </v-select>
    </v-col>
    <v-col cols="9" sm="4" md="4">
      <v-text-field
          v-model="editedItem.price"
          type="number"
          min="0"
          :label="$filters.capitalize($t('stock_parts.price') )"
          ></v-text-field>
    </v-col>
    <v-col cols="3" sm="2" md="2">
      <SelectCurrency
          v-bind:currency="editedItem.price_currency"
          @change="chooseCurrency"
          ></SelectCurrency>
    </v-col><v-col cols="9" sm="4" md="4">
      <v-text-field
          v-model="editedItem.resell_price"
          type="number"
          min="0"
          :label="$filters.capitalize($t('stock_parts.resell_price') )"
          ></v-text-field>
    </v-col>
    <v-col cols="3" sm="2" md="2">
      <SelectCurrency
          v-bind:currency="editedItem.resell_price_currency"
          @change="chooseResellCurrency"
          ></SelectCurrency>
    </v-col>
    <v-col cols="12" sm="6" md="6">
      <v-autocomplete
          v-model="editedItem.supplier_ids"
          :items="$store.state.suppliers.search"
          item-title="name"
          item-value="pk"
          :label="$filters.capitalize($t('suppliers.label', 10) )"
          v-model:search="autocompleteSearchSupplier"
          :loading="$store.state.suppliers.isSearching"
          :clearable="true"
          variant="solo"
          cache-items
          :chips="true"
          deletable-chips
          :multiple="true"
          >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{ $filters.capitalize(
                $t("search_for", { noun: $t("suppliers.label", 1) })
                )
                }}
              </v-list-item-title>
            </v-list-item>
          </template>
      </v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
  import SelectCurrency from "@/components/SelectCurrency";
  import CategoryForm from "@/components/CategoryForm";
  import filters from "@/mixins/filters";
  import { capitalize, alertPopup, isNone } from "@/functions.js";

  export default {
    mixins: [filters],

    components: {
      CategoryForm: CategoryForm,
      SelectCurrency: SelectCurrency,
    },

    computed: {
      editedItem: function() {
        return this.$store.getters.dataEdit(this.moduleName);
      },
      is_unit: function() {
        return this.$store.state.stock_parts.edit.unity === "un";
      },
      unityList: function() {
        let base = this.$store.getters.unityItems;
        // if new stockpart then all options available
        if (isNone(this.$store.state.stock_parts.edit.pk)) return base
        //otherwise can't change to or from unit
        for (let i = 0; i < base.length; i++) {
          base[i].disabled = base[i].id === "un" ? !this.is_unit : this.is_unit;
        }
        return base;
      }
    },
    methods: {
      chooseCurrency(value) {
        this.editedItem.price_currency = value;
      },
      chooseResellCurrency(value) {
        this.editedItem.resell_price_currency = value;
      }
    },

    watch: {
      autocompleteSearchSupplier(val) {
        if (!this.$store.getters.canSearch("suppliers")) return;
        this.$store.dispatch("suppliers/searchItems", { value: val });
      }
    },

    data() {
      return {
        moduleName: "stock_parts",
        currencies: [
          { symbol: "€", key: "EUR" },
          { symbol: "$", key: "USD" },
          { symbol: "N$", key: "NAD" },
          { symbol: "FC", key: "KMF" }
        ],
        autocompleteSearchSupplier: null,
      }
    }
  }
</script>
