<template>
  <v-container pa-0 ma-0>
    <v-row>
      <CategoryForm v-show='askStockPart' ></CategoryForm>
      <v-col v-show="askStockPart" cols="12" sm="6" md="6">
        <v-autocomplete
          v-model="editedItem.stock_part_id"
          :items="$store.state.stock_parts.search"
          :item-props='stockPartProps'
          :label="$filters.capitalize($t('stock_parts.label') )"
          v-model:search="autocompleteSearchStockPart"
          :loading="$store.state.stock_parts.isSearching"
          append-icon="mdi-text-box-search"          
          @update:modelValue="stockPartSelected"
          variant="solo"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{$filters.capitalize(
                  $t("search_for", { noun: $t("stock_parts.label", 1) })
                    )
                }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-text-field
          v-model="editedItem.serial"
          :label="$filters.capitalize($t('units.serial', 1) )"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-text-field
          v-model="editedItem.qr_code"
          :label="$filters.capitalize($t('units.code', 1) )"
        ></v-text-field>
      </v-col>
      <StockagePlaceForm
          v-show='askPlace'
        :stockageId="stockageId"
          :askStockage='askStockage'
        @subFormUpdate="subFormUpdateEvent"
      >
      </StockagePlaceForm>
    </v-row>
  </v-container>
</template>

<script>
import CategoryForm from "@/components/CategoryForm"
import StockagePlaceForm from "@/components/StockagePlaceForm"
import filters from "@/mixins/filters";
import { isNone } from "@/functions.js";

export default {
  mixins: [filters],

  components: {
    CategoryForm: CategoryForm,
    StockagePlaceForm: StockagePlaceForm,
  },
// TODO: check changes in StockagePlaceForm aren't a problem
  props: {
    askStockPart: { type: Boolean, required: false, default: true },
    askPlace: { type: Boolean, required: false, default: true },
    askStockage: { type:Boolean, required: false, default: true },
    partFilter: { type: String, required: false, default: "" }, // un, pi, ...
    producerId: { type: Number, required: false, default: undefined },
    stockageId: { type: Number, required: false, default: undefined },
    placeId: { type: Number, required: false, default: undefined },
    stockPartId: { type: Number, required: false, default: undefined },
    producerTypeId: { type: Number, required: false, default: undefined },
  },

  computed: {
    editedItem: function () {
      return this.$store.getters.dataEdit("units");
    },
  },
  mounted() {
    if (this.producerId) {
      this.$store.dispatch("part_categories/getItemsFromProducer", [
        this.producerId,
      ]);
    } else {
      this.$store.dispatch("part_categories/getItems", { size: 40 });
    }
    if (!isNone(this.stockPartId)) {
      this.editedItem.stock_part_id = this.stockPartId;
    }
    if (!this.askStockPart) {
      // in that case, add reference button is permitted by default
      this.serialSelection = true;
    }
  },

  methods: {
    stockPartProps(item) {
      if (typeof item !== 'object' || item === null) return;
      const titleParts = [];
      if (item.name) {
        titleParts.push(item.name);
      }
      if (item.name_en) {
        titleParts.push(item.name_en);
      }
      if (item.ref) {
        titleParts.push(item.ref);
      }
      const newItemTitle = titleParts.join(' -- ');
      const newItem = { ...item, title: newItemTitle, selection: newItemTitle, value: item.pk || 'error_item_props' };
      return newItem;
    },
    formatTextTitle(item){
      return item.shelf != null ? item.stockage.name + ' - ' + item.cabinet + '/'+ item.shelf:  item.stockage.name + ' - ' + item.cabinet    
    },

    updateCategory(val) {
      if(val){
        var payload = { value: val, size: 10 };
        this.$store.dispatch("part_categories/searchItems", payload);
      }    
    },

    updateStockPart(val) {
      var payload = { value: val, size: 50, category_id: this.category, is_unit: true };
      if (!isNone(this.producerTypeId)) {
        payload["producer_types"] = this.producerTypeId;
      }
      if (!isNone(this.partFilter)) {
        payload["unity"] = self.partFilter;
      }
      this.$store.dispatch("stock_parts/searchItems", payload);
      
    },

    stockPartSelected(item) {
      // TODO: filter stockages by stockpart__category__producertype
      if (!isNone(item)) {
        this.editedItem.stock_part_id = item;
        this.serialSelection = item.unity === "un";
      } else {
        this.serialSelection = false;
      }
      this.$store.dispatch("stock_parts/updateEdit", item);
    },
    subFormUpdateEvent(type,id=undefined){
      this.editedItem.place_id = id
    },
  },

  watch: {
    autocompleteSearchCategory(val) {
      if (!this.$store.getters.canSearch("part_categories")) return;
      if (!this.category) {
        this.updateCategory(val);
      }
    },
    autocompleteSearchStockPart(val) {
      if (!this.$store.getters.canSearch("stock_parts")) return;
      if (!this.editedItem.stock_part_id) {
        this.updateStockPart(val);
      }
    },
    category() {
      if (isNone(this.editedItem.stock_part_id)) {
        this.updateStockPart("");
      }
    },
  },

  data() {
    return {
     // autocompleteSearchPlace: null,
      autocompleteSearchStockPart: null,
      serialSelection: false,
    };
  },
};
</script>
