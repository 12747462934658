<template>
  <v-container>
    <div style="display: none">
      <SimpleDialog v-bind:btn-name="''" v-bind:form-title="$t('actions.convert_to_unit')"
                    v-bind:model="showConvertToUnit" v-bind:disabled="false" @close="showConvertToUnit = false"
      >
        <template v-slot:content>
          <v-card-text v-show="places.length>0">
            <v-card-title>{{ $t("part_edition.subtitle") }}</v-card-title>
            <v-table fixed-header class="my-2">
              <thead>
              <tr>
                <th>{{ $filters.capitalize($t("stockages.region")) }}</th>
                <th>{{ $filters.capitalize($t("stockages.label", 1)) }}</th>
                <th>{{ $filters.capitalize($t("places.label", 1)) }}</th>
                <th>{{ $filters.capitalize($t("consumables.quantity")) }}</th>
              </tr>
              <tr
                v-for="pl in places"
                :key="pl.place"
                :style="{ backgroundColor: pl.quantity < 0 ? 'lightcoral' : '' }"
               >
                <td> {{ $filters.capitalize(pl.place__stockage__region ) }}
                </td>
                <td>{{ $filters.capitalize(pl.place__stockage__name ) }}</td>
                <td> {{ $filters.capitalize(pl.place__cabinet ) }}
                  {{ $filters.capitalize(pl.place__shelf ) }}
                </td>
                <td>{{ pl.quantity }} <v-icon v-if='pl.quantity < 0' icon='mdi-alert-circle' />
                </td>
              </tr>
              </thead>
            </v-table>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary darken-1"
              class="mb-2"
              :disabled='isDisabled'
              @click.stop="convertItem"
            >
              {{ $filters.capitalize($t("actions.convert_to_unit")) }}
            </v-btn>
          </v-card-actions>
        </template>
      </SimpleDialog>
    </div>

    <v-row class="text-center">
      <v-col class="mb-5" cols="12">
        <Crud
          v-bind:headers="headers"
          v-bind:moduleName="moduleName"
          v-bind:actionAddConsumable="true"
          v-bind:actionAddPart="true"
          v-bind:actionConvertToUnit="true"
          @addItem="addItemEvent"
          @editItem="editItemEvent"
          @convertItemToUnit="convertItemToUnitEvent"
        >
          <template v-slot:edit_content>
            <StockPartForm/>
          </template>
        </Crud>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Crud from "@/components/Crud";
import StockPartForm from "@/components/StockPartForm";
import SimpleDialog from "@/components/SimpleDialog.vue";
import filters from "@/mixins/filters";
import { capitalize, alertPopup, isNone } from "@/functions.js";

export default {
  mixins: [filters],

  components: {
    SimpleDialog:SimpleDialog,
    Crud: Crud,
    StockPartForm: StockPartForm,
  },

  computed: {
    editedItem: function() {
      return this.$store.getters.dataEdit(this.moduleName);
    },
  },

  methods: {
    // TODO: stockage to be filtered by stock_part__category__producer_type
    addItemEvent() {
      this.$store.dispatch("part_categories/searchItems", { value: "" });
      this.$store.dispatch("suppliers/searchItems", { value: "" });
    },
    editItemEvent() {
      this.$store.dispatch("part_categories/initSearch", {
        item_ids: this.editedItem.category_id
      });
      this.editedItem.image = undefined; // No image modification here
      if (!isNone(this.editedItem.supplier_ids)) {
        this.$store.dispatch("suppliers/initSearch", {
          item_ids: this.editedItem.supplier_ids
        });
      }
    },
    convertItemToUnitEvent(item) {
      this.itemToConvert = item;
      this.$store.dispatch("stock_parts/getToUnitInfo", item.pk).then((info) => {
        this.places = info;
        this.showConvertToUnit = true;
        this.isDisabled = info?.some(item => item.quantity < 0 );
      });
    },
    convertItem() {
      alertPopup(this.$t("dialog.convert_to_unit_confirm")).then((result) => {
        if (result) {
          this.$store.dispatch("stock_parts/convertToUnit", this.itemToConvert.pk).then(() => {
            this.showConvertToUnit = false;
          });
        }
      });
    },
    },
  data() {
    return {
      moduleName: "stock_parts",
      showConvertToUnit: false,
      itemToConvert: undefined,
      isDisabled: false,
      places: [],
      headers: [
        { title: "", key: "obsolete" },
        {
          title: this.$t("part_categories.label"),
          key: "category.name"
        },
        { title: this.$t("stock_parts.name"), key: "name" },
        { title: this.$t("stock_parts.name_en"), key: "name_en" },
        { title: this.$t("stock_parts.ref"), key: "ref" },
        { title: this.$t("stock_parts.price"), key: "price" },
        { title: this.$t("stock_parts.resell_price"), key: "resell_price" },
        { title: this.$t("stock_parts.image"), key: "image" },
        {
          title: capitalize(this.$t("unity.label", 1)),
          key: "unity",
          align: " d-none d-md-table-cell"
        },
        {
          title: capitalize(this.$t("suppliers.label", 10)),
          key: "suppliers",
          align: " d-none d-md-table-cell",
          sortable: false
        },
        {
          title: this.$t("actions.label"),
          key: "actions",
          sortable: false
        }
      ],
    };
  }
};
</script>
