<template>
  <v-container>
    <v-dialog
      v-model="showRangeSelection"
      width="unset"
      @click:outside="closeRangeSelection"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{$filters.capitalize( $t("export") ) }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <VueDatePicker
              v-model="range"
              v-if="showRangeSelection"
              on-click-outside="closeRangeSelection"
              :inline="{ input: true }" text-input auto-apply
              :locale="$i18n.locale"
              :first-day-of-week="1"
              range
              show-week
              :enable-time-picker="false"
            ></VueDatePicker>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary lighten-2"
            variant="text"
            @click="showRangeSelection = false"
            >{{$filters.capitalize( $t("cancel") ) }}</v-btn
          >
          <v-btn color="success" @click="download()">{{$filters.capitalize(
            $t("export") )
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- TODO: change the back-end to have the sum of pruducers and not a sum of powerstations
  <h3 class="ml-4 mt-2"
    ><v-icon>mdi-wind-turbine</v-icon> {{$filters.capitalize( $t('production.section_wind') ) }}&nbsp;:
    {{$filters.formatFloat(wind_monthly_instant )}} kW {{ $t('production.instantly', wind_monthly_instant) }} &mdash;
    {{$filters.formatFloat(wind_monthly_cumul/1000 )}} MWh {{ $t('production.cumulative', wind_monthly_cumul/1000) }}
  </h3>
  -->
    <v-table fixed-header class="my-2">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">{{$filters.capitalize( $t("production.name") ) }}</th>
            <th class="text-left">
              {{$filters.capitalize( $t("production.last_underproduction") ) }}
            </th>
            <th class="text-left">{{$filters.capitalize( $t("production.power") ) }}</th>
            <th class="text-left">{{$filters.capitalize( $t("production.wind") ) }}</th>
            <th class="text-left">{{$filters.capitalize( $t("production.dispo") ) }}</th>
            <th class="text-left">
              {{$filters.capitalize( $t("production.dispo_temps") ) }}
            </th>
            <th class="text-left">
              {{$filters.capitalize( $t("production.last_data") ) }}
            </th>
            <th class="text-left">{{$filters.capitalize( $t("production.links") ) }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="prod in wind_items" :key="prod.pk">
            <td>
              <v-badge
                class="custom-badge"
                dot
                :color="prod.status_color"
              ></v-badge>
              <router-link
                :to="{
                  name: 'intervention_reports',
                  query: { producer_id: prod.producer_id },
                }"
              >
                {{$filters.capitalize( prod.display_name ) }}
              </router-link>
              <v-icon v-if="prod.limitation_ongoing">mdi-tortoise</v-icon>
            </td>
            <td v-if="prod.current_underprod">
              {{$filters.capitalize( $t("production.current_underproduction") ) }} ({{$filters.capitalize(
                prod.last_underprod )
              }})
            </td>
            <td v-else>{{$filters.capitalize( prod.last_underprod ) }}</td>
            <td>{{$filters.formatFloat( prod.power ) }} kW</td>
            <td>{{$filters.formatFloat( prod.wind ) }} m/s</td>
            <td>
              <v-progress-circular
                class="ma-1"
                :rotate="90"
                :size="42"
                :width="4"
                model-value="prod.dispo_p"
                :color="prod.dispo_color"
                >{{$filters.formatPercentage( prod.dispo ) }}
              </v-progress-circular>
            </td>
            <td>
              <v-progress-circular
                class="ma-1"
                :rotate="90"
                :size="42"
                :width="4"
                model-value="prod.dispo_temps_p"
                :color="prod.dispo_temps_color"
                >{{$filters.formatPercentage( prod.dispo_temps ) }}
              </v-progress-circular>
            </td>
            <td :class="prod.last_data_class">
              {{$filters.formatDateTime( prod.created_at ) }}
            </td>
            <td>
              <div class="download-div" v-show="prod.is_alarm_compatible">
                    <v-btn class="mr-3" icon="true">
                      <router-link
                        :to="{
                          name: 'alarms',
                          query: { producer_id: prod.producer_id },
                        }"
                        target="_blank"
                      >
                        <v-icon
                          color="red"
                          v-show="prod.alarm_ongoing"
                          >mdi-alarm-light</v-icon
                        >
                        <v-icon
                          color="grey"
                          v-show="!prod.alarm_ongoing"
                          >mdi-alarm-light</v-icon
                        >
                      </router-link>
                    </v-btn>
              </div>

              <span class="pl-12" v-show="!prod.is_alarm_compatible"></span>

                  <v-btn
                    class="align-right orange white--text mr-3"
                    icon="true"
                    :href="
                      'http://grafana.innovent.fr/d/dLzJvuP7k/vue-eolienne?orgId=1&from=now-30d&to=now&var-pid=' +
                      prod.eolienne_id
                    "
                    target="_blank"
                  >
                    <v-icon>mdi-chart-areaspline</v-icon>
                  </v-btn>

              <div class="download-div">
                    <v-btn
                      v-show="!prod.show_spinner"
                      @click.prevent="openRangeSelection(prod.producer_id)"
                      class="primary white--text"
                      icon="true"
                    >
                      <v-icon>mdi-download</v-icon>
                    </v-btn>

                <v-progress-circular
                  v-show="prod.show_spinner"
                  class="pr-3"
                  size="28"
                  :rotate="90"
                  :indeterminate="true"
                >
                </v-progress-circular>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-table>
  </v-container>
</template>

<script>
import moment from "moment";
import { sumValues } from "@/functions.js";
import { isNone } from "@/functions.js";
import utils from "@/mixins/utils";
import i18n from "@/i18n";

export default {
  mixins: [utils],
  computed: {
    wind_items() {
      if (this.wind_productions?.length == 0) return []
      return this.wind_productions.map(item=>(
        {...item,
          status_color: this.statusColor(item.running),
          dispo_p: item.dispo * 100,
          dispo_color: this.get_progress_color(item.dispo),
          dispo_temps_p: item.dispo_temps * 100,
          dispo_temps_color: this.get_progress_color(item.dispo_temps),
          last_data_class: this.getLastDataClass(item.created_at),
          alarm_ongoing: this.alarmOngoing(item.alarm_ongoing),
          show_spinner: this.showSpinner(item.producer_id),
        }))
    },
  },

  methods: {
    getLastDataClass(last) {
      let last_ = moment(last);
      if (last_ < this.limit_5_hour) {
        return "over_5h";
      } else if (last_ < this.limit_3_hour) {
        return "over_3h";
      } else if (last_ < this.limit_1_hour) {
        return "over_1h";
      }
      return "";
    },

    updateData() {
      this.$store
        .dispatch(`telemetries/windCurrent_producerItems`, {
          ids: [
            1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
            21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
            38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54,
            55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71,
            72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 88, 89, 90, 91, 92,
            93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 113, 114,
            116, 117, 125, 126, 127, 128, 131, 139, 140, 141, 191, 204, 130, 109,
            106, 135
          ],
        })
        .then((items) => {
          this.wind_productions = items;
        });

//      this.$store
//        .dispatch(`telemetries/windMonthlyProduction`)
//        .then((items) => {
//          let val = sumValues(items);
//          this.wind_monthly_cumul = val;
//        });
//      this.$store
//        .dispatch(`telemetries/windInstantProduction`)
//        .then((items) => {
//          let val = sumValues(items);
//          this.wind_monthly_instant = val;
//        });
    },

    statusColor(status) {
      if (status) {
        return "success";
      }
      return "error";
    },

    get_progress_color(value) {
      const value_ = parseFloat(value);
      if (value_ < 0.7) {
        return "red";
      } else if (value_ < 0.8) {
        return "orange";
      } else if (value_ < 0.9) {
        return "#FFD700";
      } else if (value_ < 0.95) {
        return "light-green";
      } else if (value_ <= 1.0) {
        return "green";
      }
      return "blue";
    },

    openRangeSelection(prod_id) {
      this.producer_id = prod_id;
      this.showRangeSelection = true;
    },

    closeRangeSelection() {
      this.showRangeSelection = false;
      this.producer_id = undefined;
    },

    download() {
      if (isNone(this.range) || this.range.length < 2) {
        const msg = i18n.global.t("snack.no_range");
        this.$store.dispatch("snackbar/showError", msg);
        return;
      }
      this.$store.dispatch("onLoading");
// TODO: refacto date handling
      var start = this.range[0];
      var end = this.range[1];
      if (start > end) {
        start = this.range[1];
        end = this.range[0];
      }
      start.setHours(0,0,0,0);
      end.setHours(23,59,59,999);
      const payload = {
        start: start,
        end: end,
        producer_id: this.producer_id,
      };
      this.getFile(
        "telemetries/wind/producer/export_csv/",
        "",
        "text/csv",
        "blob",
        payload,
      );
      this.closeRangeSelection();
    },

    showSpinner(prod_id) {
      return this.producer_id === prod_id;
    },

    alarmOngoing(alarm) {
      return Object.keys(alarm).length !== 0;
    },
  },


  mounted() {
    this.updateData();

    this.$nextTick(function () {
      this.intervalId = window.setInterval(
        () => {
          this.updateData();
        },
        10 * 60 * 1000,
      );
    });
  },

  unmounted() {
    window.clearInterval(this.intervalId);
    },

  data() {
    return {
      intervalId: undefined,
      wind_productions: [],
      //wind_monthly_cumul: 0.0,
      //wind_monthly_instant: 0.0,
      limit_1_hour: moment().subtract(1, "hours"),
      limit_3_hour: moment().subtract(3, "hours"),
      limit_5_hour: moment().subtract(5, "hours"),
      showRangeSelection: false,
      range: [],
      producer_id: undefined,
    };
  },
};
</script>

<style scoped>
/* Vuetify ugly sticky header hack */
:deep(.v-data-table__wrapper) {
  height: calc(100vh - 60px) !important;
}

.custom-badge {
  position: relative;
  top: -10px;
  left: -5px;
}

td.over_1h {
  color: #ebcc3d;
}
td.over_3h {
  color: #ca8109;
}
td.over_5h {
  color: #eb0a0a;
}
.download-div > div.v-picker {
  position: absolute;
  right: 15px;
  top: 54px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}
.download-div {
  display: inline-block;
}
</style>
