<template>
  <v-container style="width: auto">
    <v-dialog
        v-model="showDialog"
        :content-class="content_class"
        :fullscreen="xs"
        @click:outside="closeDialog"
        >
        <template v-slot:activator="props">
          <v-btn
              color="primary-darken-1"
              class="mb-2"
              v-bind="props"
              :disabled="disabled"
              @click.stop="addItemBtn"
              >
              {{$filters.capitalize( btnName ) }}
          </v-btn>
        </template>

      <v-card>
        <v-card-title>
          <span class="headline">{{$filters.capitalize( formTitle ) }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <slot name="content" />
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary lighten-2" variant = 'text' @click="closeDialog">{{$filters.capitalize(
            $t("close") )
            }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { useDisplay } from "vuetify";
  export default {
    props: {
      model: { type: Boolean, required: true },
      disabled: { type: Boolean, required: false, default: false },
      formTitle: { type: String, required: true },
      btnName: { type: String, required: true },
      smallWidth: { type: Boolean, required: false, default: false },
    },

    computed: {
      showDialog() {
        return this.model;
      },
      content_class() {
        if (this.smallWidth) {
          return "v-dialog--small";
        }
        return "v-dialog--large";
      },
      xs() {
        return useDisplay().xs.value
      },
    },
    methods: {
      addItemBtn() {
        this.$emit("add");
      },

      closeDialog() {
        this.$emit("close");
      },
    },

    data() {
      return {};
    },
  };
</script>

<style lang="scss">
:deep(.v-dialog--large) {
  width: 75vw;
}
:deep(.v-dialog--small) {
  width: 75vw;
}

      @media screen and (max-width: 600px) {
        :deep(.v-dialog--large) {
          width: 100vw;
        }
        :deep(.v-dialog--small) {
          width: 100vw;
        }
      }
      /* Desktop */
      @media screen and (min-width: 768px) {
        :deep(.v-dialog--small) {
          width: 40vw;
        }
      }
      @media screen and (min-width: 1264px) {
        :deep(.v-dialog--small) {
          width: 30vw;
        }
      }
</style>
