<template>
  <v-container class="pa-0 ma-0">
    <div style="display: none">
      <v-dialog
          v-model="showAddConsumedPartDialog"
          >
          <PartSelect
              :producerTypeId='editedItem.producer.producer_type_id'
              @addConsumedPart='editConsumedPart'
              @closeDialog='showAddConsumedPartDialog = false'
              />
      </v-dialog>
        <EditDialog
            :model="showEditConsumedPart"
            :moduleName="'parts'"
            :formTitle="formTitle"
            max-width="75%"
            @save="saveConsumedPart"
            @close="showEditConsumedPart = false"
            @click:outside="showEditConsumedPart = false"
            >
            <!-- Refacto this bit -->
        <template v-slot:edit_content>
          <v-container>
            <v-row :class="{'error-background': isQuantityGreaterThanStock}">
              <v-col v-bind='colProps'>
                <div class='field-name'>{{ $filters.capitalize($t("part_categories.label")) }}</div>
                <p class="text-body-1 text--primary">
                {{ editedLine.stock_part.category__name }}
                </p>
              </v-col>
              <v-col v-bind='colProps'>
                <div class='field-name'>{{ $filters.capitalize($t("part_categories.label")) }}</div>
                <div class='field-name'>{{ $filters.capitalize($t("stock_parts.name")) }}</div>
                <p class="text-body-1 text--primary">
                {{ editedLine.stock_part.name }} -- {{ editedLine.stock_part.ref }}
                </p>
              </v-col>
              <v-col v-bind='colProps'>
                <div class='field-name'>
                  {{ $filters.capitalize($t("intervention_reports.quantity_in_stock")) }}
                </div>
                <p class="text-body-1 text--primary">
                {{ editedLine.total_in_stock }}
                </p>
              </v-col>
              <v-col v-bind='colProps'>
                <div class='field-name'>
                  {{ $filters.capitalize($t("stockages.label")) }}
                </div>
                <p class="text-body-1 text--primary">
                {{ editedLine.place.stockage.name }}
                </p>
                <p class="text-body-1 text--primary">
                {{ editedLine.place_names }}
                </p>
              </v-col>
              <v-col v-bind='colProps'>
                <v-text-field
                    v-model.number="editedLine.quantity"
                    hide-details
                    type="number"
                    :label="$filters.capitalize($t('intervention_reports.quantity_consumed'))"
                    />
                  <v-alert
                      v-show="isQuantityGreaterThanStock"
                      type="error"
                      dense
                      >
                      {{$t('intervention_reports.quantity_exceeds_stock')}}
                  </v-alert>
              </v-col>
            </v-row>
          </v-container>
        </template>
        </EditDialog>
    </div>
    <v-container>
      <v-row class="pa-0 ma-0">
        <v-col cols="12" class="subtitle-block">
          {{ $filters.capitalize($t("intervention_reports.affectation.consumed_part"))}}
        </v-col>
      </v-row>
      <v-row class="pa-0 ma-0">
        <v-col cols="12" class="px-0 mx-0 mt-0 pt-0">
          <v-table class="fill-width">
            <thead>
              <tr>
                <th>{{$t('stock_parts.name_full')}}</th>
                <th>{{$t('stockages.label',1)}}</th>
                <th>{{$t('consumables.quantity')}}</th>
                <th>{{$t('actions.label')}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(cons, index) in editedItem.consumed_parts" :key="index">
                <td> {{ $store.getters.getStockPartDisplayNameFull(cons.stock_part) }}
                </td>
                <td>{{cons.place?.stockage?.name}}</td>
                <td>{{cons.quantity}}</td>
                <td>
                  <v-btn
                      flat
                      icon='mdi mdi-pencil'
                      class='mr-1'
                      @click='editConsumedPart(cons, index)'>
                  </v-btn>
                  <v-btn
                      flat
                      icon='mdi mdi-delete'
                      @click='deleteConsumedPart(index)'>
                  </v-btn>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <v-btn
                      icon='mdi mdi-plus'
                      class='my-2 ml-2'
                      color='primary-darken-1'
                      variant='elevated'
                      @click='openConsumePart()'
                      ></v-btn>
                </td>
              </tr>
            </tfoot>
          </v-table>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
<script>
  import EditDialog from "@/components/EditDialog";
  import PartSelect from "@/components/intervention_report/PartSelect";
  import { isNone } from "@/functions";

  export default {
    components: {
      EditDialog: EditDialog,
      PartSelect: PartSelect,
    },
    methods: {
      openConsumePart(){
        this.showAddConsumedPartDialog = true
      },
      saveConsumedPart(){
        let index = this.editedLine.index
        if (isNone(this.editedLine.pk) && index == undefined) this.editedItem.consumed_parts.push(this.editedLine)
        if (index != undefined) this.editedItem.consumed_parts[index]=this.editedLine
        this.showAddConsumedPartDialog = false
        this.showEditConsumedPart = false;
      },
      editConsumedPart(cons, index){
        this.editedLine = {...cons, 'index': index}
        this.showEditConsumedPart = true;
      },
      deleteConsumedPart(index){
        this.editedItem.consumed_parts.splice(index, 1)
      },
      cleanupConsumedPartDialog(){
        this.showAddConsumedPartDialog = false;
      },
    },
    computed: {
      editedItem: function () {
        return this.$store.state[`${this.moduleName}`].edition;
      },
      formTitle: function() {
        if (isNone(this.editedLine?.pk)) return this.$t('parts.creation_label')
        else return this.$t('parts.edition_label')
      },
      isQuantityGreaterThanStock(){
        return Math.abs(this.editedLine.quantity)> this.editedLine.total_in_stock;
      },
    },
    data() {
      return {
        moduleName: 'intervention_reports',
        showAddConsumedPartDialog: false,
        showEditConsumedPart: false,
        editedLine: undefined,
        colProps: {
          cols: 12,  // Full width on small screens
          sm: 6,     // Half width on small screens
          md: 4,     // 4 columns per row on medium screens
          lg: 3,     // 3 columns per row on large screens
        }
      }
    },
  }
</script>
<style scoped>
/* Custom class to change background color */
.error-background {
  background-color: #ffcccb !important; /* Light red background */
}
.subtitle-block {
  background-color: #1976D2;
  color: white;
  display: inline-block; /* To keep it as a block with proper text wrap */
  font-weight: bold;
  font-size: 1.25rem; /* Increase font size for better readability */
  letter-spacing: 0.5px; /* Slightly space out letters for improved readability */
}
</style>
