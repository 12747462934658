import { EventModel } from "@bryntum/scheduler";

export default class Appointment extends EventModel {
  static get fields() {
    return [
      { name: "name", type: "string", defaultValue: "" },
      { name: "description", type: "string", defaultValue: "" },
      { name: "ilk", type: "string", defaultValue: "" },
      { name: "producer_id", type: "int", defaultValue: null },
      { name: "locked", type: "boolean", defaultValue: false },
      { name: "is_remote", type: "boolean", defaultValue: null },
      { name: "startDate", type: "date", defaultValue: null },
      { name: "endDate", type: "date", defaultValue: null },
      { name: "resourceId", type: "int", defaultValue: null },
    ];
  }
}
