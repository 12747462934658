<template>
  <v-menu
      v-model='isOpened'
    :return-value="stockage_id"
    :open-on-hover="true"
    :close-on-click="true"
    :close-on-content-click="true"
    :offset="0"
  >
    <template v-slot:activator="{props}">
      <v-btn
        :x-small="smAndDown"
        color="primary"
        dark
        v-bind="props"
      >
        <div>
          {{ currentStockage }}
        </div>
        <v-progress-circular
          class="ml-2"
          v-show="loading_"
          :indeterminate="true"
        ></v-progress-circular>
        <v-icon>mdi-menu-down</v-icon>
      </v-btn>
    </template>

    <v-card scrollable>
      <v-card-text style="max-height: 50vh; overflow-y: scroll">
        <v-list>
          <v-list-item
            v-for="item in stockages"
            :key="item.stockage_id"
            @click="chooseStockage(item)"
          >
            <v-list-item-title>{{ item.stockage_name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { isNone } from "@/functions.js";
import { useDisplay } from "vuetify";

export default {
  props: {
    stockage_id: { type: [Number, String], required: false, default: "" },
    loading: { type: Boolean, required: false, default: false },
  },
  computed: {
    stockage_items() {
      return this.$store.state.stockages.x_prod_type;
    },
    stockages(){
      return Array.from(
        new Map(
            this.stockage_items?.map(x => [x.stockage_name, { stockage_name: x.stockage_name, stockage_id: x.stockage_id }])
            ).values())
    },
    smAndDown(){ return useDisplay().smAndDown.value}
  },

  methods: {
    updateCurrentStockage(stockage_id) {
      // update selected stockage/producer type label
      let index = this.stockages.findIndex(
        (i) =>
          i.stockage_id === stockage_id
      );
      if (index !== -1) {
        this.currentStockage = this.stockages[index].stockage_name;
      } else {
        this.currentStockage = this.$t("actions.choose_stock");
      }
      this.producer_types = this.stockage_items.filter(x => x.stockage_name == this.currentStockage)
    },

    chooseStockage(select) {
      // on select
      this.updateCurrentStockage(select.stockage_id);
      this.$emit("chooseStock", {
        stockage_id: select.stockage_id,
       // producer_type_id: select.producer_type_id,
        producer_types: this.producer_types
      });
    },
    loadStockagesIfEmpty() {
      if (this.stockage_items?.length == 0) {
        this.$store.dispatch("stockages/getXProdType")
      }
    },
  },

  mounted() {
    this.loading_ = this.loading;
    this.loadStockagesIfEmpty();
    if (!isNone(this.stockage_id)) {
      this.updateCurrentStockage(this.stockage_id);
    }
  },

  watch: {
    loading(newValue) {
      // changed in parent
      // https://stackoverflow.com/questions/46425633/how-to-send-updated-values-from-parent-component-to-child-component-in-vue-js
      this.loading_ = newValue;
    },
    isOpened(val) {
      if (val)  this.loadStockagesIfEmpty();
    },
  },

  data() {
    return {
      moduleName: "stockages",
      // stockage_items: [],
      loading_: false,
      isOpened: false,
      currentStockage: this.$t("actions.choose_stock"),
      producer_types: [],
    };
  },
};
</script>
