<template>
  <v-container pa-0 ma-0>
    <v-row>
      <v-col cols="12" sm="6" md="4" v-show='askDate'>
        <DatePicker
            v-bind:label="'generic.date'"
            v-bind:value="editedItem.date"
            @input="updateDate"
            >
        </DatePicker>
      </v-col>
      <v-col cols="12" sm="8" md="8" v-if="showSerial">
        <v-autocomplete
            disabled
            v-model="editedItem.unit"
            :items="localItems"
            :item-props="unitProps"
            :label="$filters.capitalize($t('units.label'))"
            v-model:search="autocompleteSearchUnit"
            :loading="$store.state.units.isSearching"
            no-filter
            append-icon="mdi-text-box-search"
            :clearable="true"
            variant="solo"
            return-object
            >
            <template v-slot:no-data>
              <v-list-item :title="$filters.capitalize( $t('search_for', { noun: $t('units.label', 1) }))"/>
            </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="12" sm="4" md="4">
        <v-select
            v-model="editedItem.kind"
            :items="affectionTypes"
            item-title="name"
            item-value="id"
            :label="$filters.capitalize($t('affectations.choose_type'))"
            :disabled="!askKind"
            variant="solo"
            @update:modelValue="selectKind"
            >
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col :v-show="showSubSelect">
        <v-card v-if="editedItem.kind == 'ir'" class="pa-4" elevation="6">
          <InternalRepairForm
              @subFormUpdate="subFormUpdateEvent"
              />
        </v-card>
        <v-card v-if="editedItem.kind == 'er'" class="pa-4" elevation="6">
          <ExternalRepairForm
              @subFormUpdate="subFormUpdateEvent"
              />
        </v-card>
        <v-card v-if="editedItem.kind == 'p' && askProducer" class="pa-4" elevation="6">
          <ProducerForm
              @updateProducer="subFormUpdateEvent('p', id=$event)"
              />
        </v-card>
        <v-card v-if="editedItem.kind == 'ss'" class="pa-4" elevation="6" >
          <StockagePlaceForm
              @subFormUpdate="subFormUpdateEvent"
              />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState } from "vuex";
  import moment from "moment";
  import InternalRepairForm from "@/components/InternalRepairForm";
  import ExternalRepairForm from "@/components/ExternalRepairForm";
  import ProducerForm from "@/components/ProducerForm";
  import StockagePlaceForm from "@/components/StockagePlaceForm";
  import DatePicker from "@/components/DatePicker";
  import { unitProps } from "@/mixins/props"

  export default {

    components: {
      InternalRepairForm,
      ExternalRepairForm,
      ProducerForm,
      StockagePlaceForm,
      DatePicker: DatePicker,
    },

    props: {
      askDate: { type: Boolean, required: false, default: true },
      askKind: { type: Boolean, required: false, default: true },
      askProducer: { type: Boolean, required: false, default: true },
      withProducer: { type: Boolean, required: false, default: true },
      showSerial: { type: Boolean, required: false, default: true },
      unitIsReturning: {type:Boolean, required:false, default:false}
    },

    computed: {
      kindId: {
        get: function () {
          return this.$store.state.affectations.kindId;
        },
        set: function (value) {
          this.$store.state.affectations.kindId = value;
        },
      },
      affectionTypes: function () {
        if (this.unitIsReturning){
          return [
            { name: this.$t("stock_state.label", 1), id: "ss" },
            { name: this.$t("trash_state.label", 1), id: "ts" },
            { name: this.$t("producers.label", 1), id: "p" },
          ];

        }
        var liste = [
          { name: this.$t("external_repairs.label", 1), id: "er" },
          { name: this.$t("internal_repairs.label", 1), id: "ir" },
          { name: this.$t("stock_state.label", 1), id: "ss" },
          { name: this.$t("trash_state.label", 1), id: "ts" },
        ];
        if (this.withProducer) {
          liste.push({ name: this.$t("producers.label", 1), id: "p" });
        }
        return liste;
      },
      editedItem: function () {
        return this.$store.getters.dataEdit("affectations");
      },
    },

    methods: {
      unitProps,
      subFormUpdateEvent(type, id=undefined) {
        // TODO: editedItem.target is not updated, not a problem as
        //         back takes id from other fieldsi: internal_repair_id etc....
        if (type == "ir") {
          this.editedItem.internal_repair =
            this.$store.state.internal_repairs.edit;
          this.editedItem.internal_repair_id = this.$store.state.internal_repairs
            .edit.pk
            ? this.$store.state.internal_repairs.edit.pk
            : 1;
        }
        if (type == "er") {
          this.editedItem.external_repair =
            this.$store.state.external_repairs.edit;
          this.editedItem.external_repair_id = this.$store.state.external_repairs
            .edit.pk
            ? this.$store.state.external_repairs.edit.pk
            : 1;
        }
        if (type == "p") {
          // producerForm returns whole object
          this.editedItem.producer = id;
          this.editedItem.producer_id = id.pk;
        }
        if(type=="ss"){
          // TODO: AAAAARGHHHHH!
          this.editedItem.stock_state = id;
          this.editedItem.stock_state_id = id;
        }
      },
      selectKind(item) {
        this.$emit('affKindChanged')
        if (item == "ts") this.showSubSelect = false;
        else this.showSubSelect = true;
        this.updateKindId();
      },

      updateKindId() {
        this.$store.dispatch("updateAffectationTarget", {
          type: this.editedItem.kind,
          id: this.kindId,
        });
        // TODO: target objects to be updated too
      },

      updateDate(value) {
        let d_ = moment(value).toDate();
        d_.setHours(10);
        d_.setMinutes(0);
        this.editedItem.date = moment(d_).format("YYYY-MM-DDThh:mm");
      },
    },

    watch: {
      kindId() {
        this.selectKind(this.editedItem.kind);
      },
      autocompleteSearchUnit(val) {
        if (!this.$store.getters.canSearch("units")) return;
        this.$store.dispatch("units/searchItems", { value: val }).then((items)=>{
          this.localItems=items;
        });
      },
    },

    data() {
      return {
        moduleName: "affectations",
        showSubSelect: false,
        autocompleteSearchUnit: null,
        localItems: [],
      };
    },
  };
</script>
