<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-3" cols="12">
        <h2 class="headline font-weight-bold mb-3">
          {{ $filters.capitalize($t("menu.home") ) }}
        </h2>

        <v-row justify="center" no-gutters>
          <p>
            {{ $filters.capitalize($t("home.welcome") ) }}
          </p>
        </v-row>
        <RiskButton></RiskButton>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card elevation="3">
          <v-card-title>
            {{ $filters.capitalize($t("home.scheduler") ) }}
          </v-card-title>

          <v-card-text>
            <span v-for="item in plannings" :key="item.id">
              <v-chip
                class="mr-2 mb-2"
                link
                color="primary_light"
                @click="redirect_to('scheduler', { regions: [item.id] })"
                >{{ $filters.capitalize(item.name ) }}</v-chip
              >
            </span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card elevation="3">
          <v-card-title>
            {{ $filters.capitalize($t("home.stockage") ) }}
          </v-card-title>

          <v-card-text>
            <span v-for="item in stockages" :key="item.item_id">
              <v-chip
                class="mr-2 mb-2"
                link
                color="primary_light"
                @click="
                  redirect_to('part_view', {
                    stockage_id: item.stockage_id,
                  })
                "
                >{{ $filters.capitalize(item.stockage_name ) }}</v-chip
              >
            </span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card elevation="3">
          <v-card-title>
            {{ $filters.capitalize($t("home.producer") ) }}
          </v-card-title>

          <v-card-text>
            <span v-for="item in producers" :key="item.pk">
              <v-chip
                class="mr-2 mb-2"
                link
                color="primary_light"
                @click="
                  redirect_to('intervention_reports', { producer_id: item.pk })
                "
              >
                {{ $filters.capitalize(item.display_name ) }}
              </v-chip>
            </span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="isWindProfil">
      <v-col cols="12">
        <v-card elevation="3">
          <v-card-title>
            {{ $filters.capitalize($t("home.automatons") ) }}
          </v-card-title>

          <v-card-text>
            <span v-for="item in automatons_list" :key="item.pk">
              <v-chip
                class="mr-2 mb-2"
                link
                color="primary_light"
                @click="redirect_to('automaton', { producer_id: item.pk })"
              >
                {{ $filters.capitalize(item.display_name ) }}
              </v-chip>
            </span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card elevation="3">
          <v-card-title>
            {{ $filters.capitalize($t("home.alarms") ) }}
          </v-card-title>

          <v-card-text>
            <span v-for="item in producer_alarms" :key="item.pk">
              <v-chip
                class="mr-2 mb-2"
                link
                color="primary_light"
                @click="redirect_to('alarms', { producer_id: item.pk })"
              >
                {{ $filters.capitalize(item.display_name ) }}
              </v-chip>
            </span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card elevation="3">
          <v-card-title>
            {{ $filters.capitalize($t("home.purchase") ) }}
          </v-card-title>

          <v-card-text>
            <span v-for="item in stockages_x_producer_type" :key="item.item_id">
              <v-chip
                class="mr-2 mb-2"
                link
                color="primary_light"
                @click="
                  redirect_to('purchase_view', {
                    stockage_id: item.stockage_id,
                    producer_type_id:item.producer_type_id,
                  })
                "
                >{{ $filters.capitalize(item.full_name )}}</v-chip
              >
            </span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RiskButton from "@/components/RiskButton";
export default {
  components: { RiskButton },
  computed: {
    //showAdvancedControls: function() { return this.$store.getters.isUserFromStaff },  // TODO for now
    isWindProfil() {
      return this.$store.getters.isWindProfil;
    },
    stockages_x_producer_type() {
      return this.$store.state.stockages.x_prod_type;
    },
    stockages(){
      return Array.from(
        new Map(
            this.stockages_x_producer_type?.map(x => [x.stockage_name, { stockage_name: x.stockage_name, stockage_id: x.stockage_id }])
            ).values())
    },
  },

  created() {
    this.$store.dispatch(`stockages/getRegions`).then((regions) => {
      this.plannings = regions;
    });
    if (this.stockages_x_producer_type?.length == 0) {
      this.$store.dispatch(`stockages/getXProdType`).then(() => {
        // this.stockages_x_producer_type = items;
      });
    }
    this.$store.dispatch(`producers/getMine`).then((producers) => {
      this.producers = producers;
      if (this.isWindProfil){
        this.automatons_list = producers.filter((p)=>p.has_automate);
      }
      this.producer_alarms = producers.filter((p)=>p.is_alarm_compatible);
    });
  },

  methods: {
    redirect_to(name, query) {
      this.$router.push({ name: name, query: query });
    },
  },

  unmounted() {
    this.plannings = [];
    this.producers = [];
    this.producer_alarms = [];
    this.automatons_list = [];
  },

  data: () => ({
    plannings: [],
    producers: [],
    producer_alarms: [],
    automatons_list: [],
  }),
};
</script>

<style>
table {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
