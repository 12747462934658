import crud from "./crud";
import crud_service from "@/services/crud"

const path = "stock_parts";
const struct = {
  name: "",
  name_en: "",
  ref: "",
  unity: "",
  // threshold: 0,
  price: null,
  price_currency: "EUR",
  resell_price_currency: "EUR",
  is_locally_bought: true,
  image: null,
  category_id: null,
  category: { producer_types: [] }, // to avoid errors on empty load
  supplier_ids: [],
};
const generic = crud(path, struct);

const state = {
  ...generic.state,
};

const getters = {
  ...generic.getters,
};

const mutations = {
  ...generic.mutations,
};

const actions = {
      addItem({ commit }, item) {
        // Create a new item
        if (item.price == "") item.price = null;
        if (item.resell_price == "") item.resell_price = null;
        return new Promise((resolve, reject) => {
          crud.post(path, item)
            .then((response) => {
              commit("updateEdit", response);
              commit("addItem", response);
              resolve(response);
            })
            .catch((error) => {
              // Check if the error is a 400 Bad Request
              if (error.response && error.response.status === 400) {
                // Handle the 400 Bad Request error
                console.error('Bad Request error:', error.response.data);
                reject(new Error('Bad Request: ' + error.response.data));
              } else {
                // Handle other errors
                console.error('An error occurred while adding the item:', error);
                reject(error);
              }
            });
        });
      },
  getToUnitInfo({ commit }, pk) {
    commit("setIsLoading", true);
    return new Promise((resolve) => {
      crud_service
        .fetch(`${path}/to_unit_info`, {id:pk})
        .then((items) => {
          commit("setIsLoading", false);
          resolve(items);
        })
        .catch(() => {
          commit("setIsLoading", false);
        });
      });
  },
  convertToUnit({ commit }, pk) {
    commit("setIsLoading", true);
    return new Promise((resolve) => {
      crud_service
        .fetch(`${path}/to_unit`, {id:pk})
        .then((items) => {
          commit("setIsLoading", false);
          resolve(items);
        })
        .catch(() => {
          commit("setIsLoading", false);
        });
    });
    },
...generic.actions,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
