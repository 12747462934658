<template>
  <v-card>
    <v-select
        v-model="stockageSelected"
        :items="$store.state.stockages.all"
        :item-props='stockageProps'
        :label="$filters.capitalize($t('stockages.label', 1))"
        @update:modelValue='updateStockage'
        variant="solo"
        return-object
        />
     <v-text-field
        v-model='searchText'
        prepend-inner-icon="mdi-magnify"
        />
     <!--- TODO: filter bit,to include:
        -  place (armoire/cabinet, etagere/shelf),
        - inc_otherproducer_types
        - part_category
        --->
     <v-data-table
        :headers='headers'
        :items="stock_list"
        :loading='$store.state.real_parts.isLoading'
        :search='searchText'
        :custom-filter='customFilter'
        @click:row='itemSelected'
        >
        <template v-slot:loading>
          <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
        </template>
     </v-data-table>
  </v-card>
</template>
<script>
  import { customFilter } from "@/functions"
  import { stockageProps } from "@/mixins/props"
  export default {
    props: {
      producerTypeId: {type: Number, required: true},
    },
    computed: {
      stock_list(){
        const alreadyInList = this.$store.state.intervention_reports.edition.consumed_parts;
        return this.$store.state.real_parts.items.filter(item => {
          const isItemInAlreadyInList = alreadyInList.some(existingItem =>
            existingItem.stock_part_id === item.pk &&
            item.place_ids.includes(existingItem.place_id)
          );
          if (isItemInAlreadyInList) return false
          return true
        });
      },
      editedItem(){
        return this.$store.state.intervention_reports.edition
      },
    },

    mounted() {
      this.stockageSelected = this.$store.state.stockages.edit;
    },

    methods:{
      customFilter,
      stockageProps,
      getList(){
        let options = {
          size: -1,
          stockage: this.stockageSelected.pk,
          producer_type: this.producerTypeId,
          lang: this.$store.getters.language,
          is_unit: false,
        };
        this.$store.dispatch("real_parts/getView", options)
      },
      updateStockage(){
        this.$store.dispatch('stockages/updateEdit', this.stockageSelected)
        this.getList();
      },
      itemSelected(click, row){
        this.editedLine = {
          stock_part: row.item,
          stock_part_id: row.item.pk,
            // place object is not sent to back just for display on parent
          place: {
            place_id: row.item.place_ids[0],
            stockage: this.stockageSelected,
          },
          place_id: row.item.place_ids[0],
          place_ids: row.item.place_ids,
          place_names: row.item.place_names,
          quantity: 0,
          total_in_stock: row.item.quantity,
        };
        this.$emit('addConsumedPart',this.editedLine)
      },
    },

    data() {
      return {
        stockageSelected: undefined,
        searchText: '',
        editedLine: undefined,
        headers:[
          {title:this.$t("part_categories.label"), key:'category__name'},
          {title:this.$t("stock_parts.name"), key:'name'},
          {title:this.$t("stock_parts.name_en"), key:'name_en'},
          {title:this.$t('stock_parts.ref'), key:'ref'},
          {title:this.$t('consumables.quantity'), key:'quantity'},
        ],
      }
    },
  }
</script>
