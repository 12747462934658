<template>
  <v-card color="white" class="pa-4 elevation-2" density="comfortable">
    <v-row justify="space-between" align="center" dense>
      <!-- Producer Autocomplete -->
      <v-col cols="12" sm="4" md="4">
        <v-autocomplete
          v-model="producerIds"
          :items="$store.state.producers.all"
          :item-props="producerProps"
          :label="$filters.capitalize($t('producers.label', 1))"
          :loading="$store.state.producers.isSearching"
          :clearable="true"
          :multiple="true"
          variant="outlined"
          density="compact"
          hide-details
          return-object
          @update:modelValue="handleChange"
          @click:clear="handleChange"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{
                  $filters.capitalize(
                    $t("search_for", { noun: $t("producers.label", 1) })
                  )
                }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-col>
      <!-- Power station -->
      <v-col cols="12" sm="4" md="4">
        <v-autocomplete
          v-model="powerStationIds"
          :items="$store.state.power_stations.search"
          :label="$filters.capitalize($t('power_stations.label'))"
          v-model:search="autocompleteSearchPowerStation"
          :loading="$store.state.power_stations.isSearching"
          :clearable="true"
          :multiple="true"
          variant="outlined"
          density="compact"
          cache-items
          item-title="name"
          item-value="pk"
          hide-details
          return-object
          @update:modelValue="handleChange"
          @click:clear="handleChange"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{
                  $filters.capitalize(
                    $t("search_for", { noun: $t("power_stations.label", 1) })
                  )
                }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-col>

      <!-- Date Picker -->
      <v-col cols="12" sm="4" md="4">
        <VueDatePicker
          v-model="date"
          @update:model-value="handleChange"
          @cleared="handleChange"
          :teleport="true"
          :enable-time-picker="false"
          input-class="v-input--is-focused"
          placeholder="Select Date"
        />
      </v-col>
    </v-row>
    <v-row justify="space-between" align="center" dense>
      <!-- User Autocomplete -->
      <v-col cols="6" sm="4" md="4">
        <v-autocomplete
          v-model="assigned_to_id"
          :items="$store.state.users.search"
          :loading="$store.state.users.isSearching"
          v-model:search="autocompleteSearchUser"
          item-title="name"
          item-value="id"
          :label="$filters.capitalize($t('intervention_reports.partner', 10))"
          variant="outlined"
          density="compact"
          hide-details
          clearable
          cache-items
          return-object
          @update:modelValue="handleChange"
          @cleared="handleChange"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{
                  $filters.capitalize(
                    $t("search_for", { noun: $t("users.label", 1) })
                  )
                }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-col>
      <!-- Regions Autocomplete -->
      <v-col cols="6" sm="4" md="4">
        <v-autocomplete
          v-model="regions"
          :items="$store.state.stockages.regions"
          v-model:search="autoCompleteSearchRegion"
          item-value="id"
          item-title="name"
          :label="$filters.capitalize($t('actions.choose_region'))"
          :multiple="true"
          variant="outlined"
          density="compact"
          clearable
          hide-details
          return-object
          @update:modelValue="handleChange"
          @cleared="handleChange"
        ></v-autocomplete>
      </v-col>
      <!-- Search Button -->
      <v-col cols="12" sm="4" md="4" class="text-right">
        <v-btn
          color="primary"
          @click="searchIt"
          variant="elevated"
          class="mt-4"
        >
          {{ $filters.capitalize($t('search')) }}
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import moment from "moment";
import utils from "@/mixins/utils";
import filters from "@/mixins/filters";
import { isNone } from "@/functions";

export default {
  mixins: [utils, filters],
  emits: ["filterToP"],
  data() {
    return {
      producerIds: [],
      powerStationIds: [],
      regions: [],
      date: null,
      assigned_to_id: null,
      autocompleteSearchUser: undefined,
      autocompleteSearchPowerStation: undefined,
      autoCompleteSearchRegion: undefined,
      searchParams: {},
    };
  },
  computed: {
    userSearchLoading() {
      return this.$store.state.users.isSearching;
    },
    filteredUsers() {
      return this.$store.state.users.search;
    },
  },
  methods: {
    producerProps(item) {
      if (typeof item !== "object" || item === null) return;
      return {
        ...item,
        title: item.display_name,
        selection: item.display_name,
        value: item.pk || "error_item_props",
      };
    },
    handleChange() {
      const date = this.date ? new Date(this.date) : null;
      const produceridsList = this.producerIds.map((item) => item.pk) ?? null;
      const stockages_ids = this.regions.flatMap((item) => item.id) ?? null;
      const powerStationidsList = this.powerStationIds.map((item) => item.pk) ?? null;
      const assigned_to_id = this.assigned_to_id ? this.assigned_to_id.id : null;

      this.searchParams = {
        fulfilled_at: date,
        producer_ids: produceridsList,
        stockages_ids: stockages_ids,
        power_stations_ids: powerStationidsList,
        assigned_to_id: assigned_to_id,
      };
    },
    searchIt() {
      this.$emit("filterToP", this.searchParams);
    },
    selectProdFromQuery() {
      const p_id = this.$route.query.producer_id;
      const proddy = this.$store.state.producers.all.find((opt) => opt.pk == p_id);
      if (proddy) {
        this.producerIds = [proddy];
      }
    },
  },
  watch: {
    autocompleteSearchPowerStation(val) {
      if (!this.$store.getters.canSearch("power_stations")) return;
      if (val) {
        this.$store.dispatch("power_stations/searchItems", { value: val });
      } else {
        this.$store.dispatch("power_stations/searchItems", {});
      }
    },
    autocompleteSearchUser(val) {
      if (!this.$store.getters.canSearch("users")) return;
      if (val) {
        this.$store.dispatch("users/searchItems", { value: val });
      } else {
        this.$store.dispatch("users/searchItems", {});
      }
    },
    autoCompleteSearchRegion(val) {
      this.$store.dispatch(`stockages/getRegionsStockageIds`).then(() => {
        if (this.$route.query.regions !== undefined) {
          var regions = this.$route.query.regions;
          if (!Array.isArray(regions)) {
            regions = [regions];
          }
          this.regions = regions;
        }
      });
    },
  },
  created() {
    this.selectProdFromQuery();

    if (!isNone(this.userId)) {
      this.$store.dispatch("users/initSearch", { item_ids: this.userId });
    }

    this.handleChange();
  },
};
</script>
