// Custom props for vuetify components
//

export function alarmProps(item) {
  if (typeof item !== 'object' || item === null) return;
  let titleParts = [];
  if (item.name) {
    titleParts.push(item.name);
  }
  if (item.name_en) {
    titleParts.push(item.name_en);
  }
  if (item.ref) {
    titleParts.push(item.ref);
  }
  const newItemTitle = titleParts.join(' -- ');
  const newItem = { ...item, title: newItemTitle, selection: newItemTitle, value: item.pk || 'error_item_props' };
  return newItem;
}

export function stockageProps(item) {
  item.title = item.name + ' -- ' + item.city
  item.value = item.pk
  return item
}

export function unitProps (item) {
  item.value = item.pk
  item.title = ''
  if (item.stock_part == undefined) return item
  if (item.stock_part.ref.length > 0) item.title = item.stock_part.ref
  else if (this.$store.getters.language === 'en') item.title = item.stock_part.name_en
  else item.title = item.stock_part.name
  item.title = item.title + ' - ' + item.serial
  return item
}
export function placeProps(item) {
  if (typeof item !== 'object' || item === null) return;

  const titleParts = [];
  if (item.cabinet) {
    titleParts.push(item.cabinet);
  }
  if (item.shelf) {
    titleParts.push(item.shelf);
  }
  const newItemTitle = titleParts.join(' - ');
  const newItem = { ...item, title: newItemTitle, selection: newItemTitle, value: item.pk || 'default_value' };
  if (this.$store.getters.language === 'en' && item.ref) {
    newItem.selection = `${item.name} – ${item.ref}`;
  }
  return newItem;
}
