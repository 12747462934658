import crud from "./crud";
import crud_service from "@/services/crud";
import { isNone } from "@/functions";

const path = "units";
const struct = {
  serial: "",
  qr_code: '',
  place_id: null,
  stock_part_id: null,
};
const generic = crud(path, struct);

const state = {
  ...generic.state,
  availables: [],
};

const getters = {
  ...generic.getters,
};

const mutations = {
  ...generic.mutations,

  setItemsAvailables(state, items) {
    state.availables = items;
  },
  setEditPlace(state, place) {
    state.edit.place_id = place.id;
    state.edit.place = place
  },
  setEditStockPart(state, stock_part_id) {
    state.edit.stock_part_id = stock_part_id;
  },
};

const actions = {
  ...generic.actions,

  setEditPlace({ commit }, item) {
    commit("setEditPlace", item);
  },
  setEditStockPart({ commit }, item) {
    commit("setEditStockPart", item);
  },
  setAvailables({commit}, items) {
    commit("setItemsAvailables", items)
  },
  getAvailables(
    { commit },
    { value, size, category_id, stockage_id, producer_type_id },
  ) {
    var payload = { size: 20 };
    if (!isNone(value)) {
      payload["search"] = value;
    }
    if (!isNone(size)) {
      payload["size"] = size;
    }
    if (!isNone(stockage_id)) {
      payload["stockage_id"] = stockage_id;
    }
    if (!isNone(producer_type_id)) {
      payload["producer_type_id"] = producer_type_id;
    }
    if (!isNone(category_id)) {
      payload["category_id"] = category_id;
    }

    return new Promise((resolve) => {
      crud_service.fetch(`${path}/available`, payload).then((items) => {
        commit("setItemsAvailables", items.results);
        resolve(items.results);
      }).catch(()=>{});
    });
  },

  searchInProduction({ commit }, value) {
    // search units that are in production state
    commit("setIsSearching", true);
    const payload = {
      search: value,
      size: 9,
    };
    return new Promise((resolve) => {
      crud_service
        .fetch(`${path}/in_production`, payload)
        .then((items) => {
          commit("setSearch", items.results);
          commit("setIsSearching", false);
          resolve();
        })
        .catch(() => {
          commit("setIsSearching", false);
        });
    });
  },

  getFromProducers({ commit }, item_ids) {
    // get units that are affected to some producers
    commit("setIsLoading", true);
    var url = `${path}/producers`;
    item_ids = item_ids.filter(function (i) {
      return !isNone(i);
    });
    if (item_ids.length === 0) {
      return;
    }
    var payload = { id: item_ids };

    return new Promise((resolve) => {
      crud_service
        .fetch(url, payload)
        .then((items) => {
          var res = items.results.map(function (i) {
            i.id = i.pk;
            //delete i.pk;
            return i;
          });
          commit("setItems", res);
          commit("setIsLoading", false);
          resolve();
        })
        .catch(() => {
          commit("setIsLoading", false);
        });
    });
  },

  searchFromProducer({ commit }, { value, item_id }) {
    // search units that are affected to a producer
    commit("setIsLoading", true);
    var url = `${path}/producers`;
    var payload = {};
    if (!isNone(item_id)) {
      payload["id"] = [item_id];
    }
    if (!isNone(value)) {
      payload["search"] = value;
    }

    return new Promise((resolve) => {
      crud_service
        .fetch(url, payload)
        .then((items) => {
          commit("setSearch", items.results);
          commit("setIsLoading", false);
          resolve(items.results);
        })
        .catch(() => {
          commit("setIsLoading", false);
        });
    });
  },

  searchFromStockPart({ commit }, { value, item_id }) {
    // search _available_ units that are linked to a stock part
    commit("setIsLoading", true);
    var payload = { stock_part_id: item_id };
    if (!isNone(value)) {
      payload["search"] = value;
    }

    return new Promise((resolve) => {
      crud_service
        .fetch(`${path}/available`, payload)
        .then((items) => {
          commit("setSearch", items.results);
          commit("setIsLoading", false);
          resolve(items.results);
        })
        .catch(() => {
          commit("setIsLoading", false);
        });
    });
  },

  getWithProdAndCat({ commit }, { producer_id, category_id }) {
    // get units of a producer in a category
    var url = `${path}/producer/${producer_id}/category/${category_id}`;

    return new Promise((resolve) => {
      crud_service
        .fetch(url)
        .then((item) => {
          var res = item.results;
          commit("setItems", res);
          resolve();
        })
        .catch(() => {});
    });
  },

  addItem({ commit }, item) {
    // Create a new item
    return new Promise((resolve) => {
      crud_service.post('parts/full', item).then((response) => {
        commit("updateEdit", response);
        commit("addItem", response);
        resolve(response);
      });
    });
  },

  checkLatest({ commit }, unit_id) {
    commit("setIsLoading", true);
    let url = `affectations/units/${unit_id}/latest`;
    return new Promise((resolve) => {
      crud_service
        .fetch(url)
        .then((item) => {
          commit("setIsLoading", false);
          resolve(item);
        })
        .catch(() => {
          commit("setIsLoading", false);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
